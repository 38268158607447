/* eslint-disable max-lines */
import itiriri from 'itiriri';
import {
  CreateRoundReq,
  CompleteRoundReq,
  HSDoseRound,
  HSFacility,
  CreateAdministeredDrugReq,
  HSAdministeredDrug,
  CreateAdministeredDoseReq,
  HSAdministeredDose,
  DoseRoundStatus,
  CreateAdministeredDrugCommentReq,
  HSAdministeredDrugComment,
  CreateRoundSegmentReq,
  UpdateRoundSegmentReq,
  CreateAdministeredDrugOutcomeReq,
  CreateAdministeredAdHocDrugReq,
  CreateAdministeredAdHocDrugCommentReq,
  HSAdministeredAdHocDrug,
  HSAdministeredAdHocDrugComment,
  CreateAdministeredAdHocDrugOutcomeReq, HSPatient,
} from 'server-openapi';
import { PersistentQueue } from '../core/queue/PersistentQueue';
import {Entry, IStorage} from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import { ISyncService } from './SyncCenter';
import { v4 as uuidv4 } from 'uuid';
import { assertNotUndefined } from '../core/utils/assertionUtils';
import { SyncUtils } from './utils/SyncUtils';
import { Logger } from '../core/logger/logger';
import { updateRound } from './utils/RoundUpdate';
import { startOfDay, subDays } from 'date-fns';
import { DateUtils } from '../core/utils/dateUtils';
import {MemoryCache} from "../core/storage/MemoryCache";

// TODO: refactor the 'set' methods in this file to use updateRound,
// using the examples already done.

export type RoundOp =
  | CreateRoundOp
  | CompleteRoundOp
  | CreateAdministeredDoseOp
  | CreateAdministeredDrugOp
  | CreateAdministeredDrugCommentOp
  | CreateRoundSegmentOp
  | UpdateRoundSegmentOp
  | CreateAdministeredDrugOutcomeOp
  | CreateAdministeredAdHocDrugOp
  | CreateAdministeredAdHocDrugCommentOp
  | CreateAdministeredAdHocDrugOutcomeOp;

// TODO: remove clinicalSystemId in the interfaces that don't need it
export interface CreateRoundOp {
  type: 'round-create';
  clinicalSystemId?: string; // make this nullable so that developers do not need to set it when making reqs
  request: CreateRoundReq;
}

export interface CompleteRoundOp {
  type: 'round-complete';
  clinicalSystemId: string;
  request: CompleteRoundReq;
}

export interface CreateAdministeredDoseOp {
  type: 'dose-create';
  clinicalSystemId?: string; // make this nullable so that developers do not need to set it when making reqs
  doseRoundClinicalSystemId: string;
  request: CreateAdministeredDoseReq;
}

export interface CreateAdministeredDrugOp {
  type: 'drug-create';
  clinicalSystemId?: string; // make this nullable so that developers do not need to set it when making reqs
  administeredDoseClinicalSystemId: string;
  doseRoundClinicalSystemId: string;
  request: CreateAdministeredDrugReq;
}

export interface CreateAdministeredDrugCommentOp {
  type: 'drug-comment-create';
  clinicalSystemId?: string; // make this nullable so that developers do not need to set it when making reqs
  administeredDrugClinicalSystemId: string;
  doseRoundClinicalSystemId: string;
  request: CreateAdministeredDrugCommentReq;
}

export interface CreateAdministeredAdHocDrugOp {
  type: 'adhoc-drug-create';
  clinicalSystemId?: string; // make this nullable so that developers do not need to set it when making reqs
  administeredDoseClinicalSystemId: string;
  doseRoundClinicalSystemId: string;
  request: CreateAdministeredAdHocDrugReq;
}

export interface CreateAdministeredAdHocDrugCommentOp {
  type: 'adhoc-drug-comment-create';
  clinicalSystemId?: string; // make this nullable so that developers do not need to set it when making reqs
  administeredDrugClinicalSystemId: string;
  doseRoundClinicalSystemId: string;
  request: CreateAdministeredAdHocDrugCommentReq;
}

export interface CreateRoundSegmentOp {
  type: 'round-segment-create';
  doseRoundClinicalSystemId: string;
  request: CreateRoundSegmentReq; // Joining a round corresponds to adding a new round segment
}

export interface UpdateRoundSegmentOp {
  type: 'round-segment-update';
  doseRoundClinicalSystemId: string;
  doseRoundSegmentClinicalSystemId: string;
  request: UpdateRoundSegmentReq; // Leaving a round corresponds to updating an existing round segment
}

export interface CreateAdministeredDrugOutcomeOp {
  type: 'create-admin-drug-outcome';
  doseRoundClinicalSystemId: string;
  administeredDrugClinicalSystemId: string;
  request: CreateAdministeredDrugOutcomeReq;
}

export interface CreateAdministeredAdHocDrugOutcomeOp {
  type: 'create-admin-adhoc-drug-outcome';
  doseRoundClinicalSystemId: string;
  administeredAdHocDrugClinicalSystemId: string;
  request: CreateAdministeredAdHocDrugOutcomeReq;
}

export interface EnqueuedDrugCreateData {
  round: HSDoseRound;
  administeredDrug: HSAdministeredDrug | HSAdministeredAdHocDrug;
}

const logger = new Logger('SyncRounds');

export class SyncRounds implements ISyncService {
  get name(): string {
    return 'SyncRounds';
  }
  private async isStale(r: HSDoseRound): Promise <boolean> {
    return (!!(r.lastUpdatedAt && r.lastUpdatedAt < DateUtils.fromDate(subDays(startOfDay(new Date()), 7))));
  }
  private async isFacilityGroup(r: HSDoseRound, facilityGroupId: string): Promise<boolean> {
    if (!r.facilityId) {
      return false;
    }
    const facilityGroup = await this.facilitiesStore.get(r.facilityId!.toString());
    return facilityGroup?.facilityGroupId?.toString() == facilityGroupId;
  }


  constructor(
    private api: SyncStreamAPI,
    private storage: MemoryCache<HSDoseRound>,
    private facilitiesStore: IStorage<HSFacility>,
    private latestChangeNumbers: IStorage<number | undefined>,
    private queue: PersistentQueue<RoundOp>,
  ) {}

  async load(facilityGroupId: string): Promise<void> {
    await this.storage.reset(async (p) => {
          return (await this.isFacilityGroup(p, facilityGroupId));
    });
  }

  enqueue = {
    createRound: async (req: CreateRoundOp) => {
      // Creating nested objects must follow the following pattern:

      // 1. Update the request with clinical system ids, and create nested requests
      const roundClinicalSystemId = uuidv4();
      req.request.round.clinicalSystemId = roundClinicalSystemId;

      const newDoses = [];
      const newDoseReqs = [];
      for (const dose of req.request.round.administeredDoses ?? []) {
        const doseClinicalSystemId = uuidv4();
        const newDose = { ...dose, clinicalSystemId: doseClinicalSystemId };
        const createDoseRequest: CreateAdministeredDoseOp = {
          type: 'dose-create',
          clinicalSystemId: doseClinicalSystemId,
          doseRoundClinicalSystemId: roundClinicalSystemId,
          request: {
            administeredDose: newDose,
            hsDoseRoundId: -1,
          },
        };
        newDoses.push(newDose);
        newDoseReqs.push(createDoseRequest);
      }
      req.request.round.administeredDoses = newDoses;

      // 2. Add the new object to the local store.
      const round = {
        key: roundClinicalSystemId,
        value: req.request.round,
      };
      await this.storage.set(round.key, round.value);

      //3. Add the nested requests to the queue
      await this.queue.unshift({ ...req, clinicalSystemId: roundClinicalSystemId });
      for (const newDoseReq of newDoseReqs) {
        await this.queue.unshift(newDoseReq);
      }

      return round;
    },

    completeRound: async (req: CompleteRoundOp) => {
      const round = await this.storage.get(req.clinicalSystemId);
      if (assertNotUndefined(round)) {
        const newRound = { ...round, status: DoseRoundStatus.Completed };
        await this.storage.set(req.clinicalSystemId, newRound);
      }
      await this.queue.unshift(req);
      return await this.storage.get(req.clinicalSystemId);
    },

    createAdministeredDose: async (req: CreateAdministeredDoseOp) => {
      // Intermediate request object containing clinical system ids
      // that will be resolved to hsIds at sync time

      // Eagerly created DTO
      const doseClinicalSystemId = uuidv4();

      const round = await this.storage.get(req.doseRoundClinicalSystemId);

      const createdDose: HSAdministeredDose = {
        ...req.request.administeredDose,
        clinicalSystemId: doseClinicalSystemId,
      };

      if (assertNotUndefined(round)) {
        const doses = [...(round?.administeredDoses ?? []), createdDose];
        const updatedRound = { ...round, administeredDoses: doses };

        await this.storage.set(req.doseRoundClinicalSystemId, updatedRound);
        await this.queue.unshift({
          ...req,
          request: {
            ...req.request,
            administeredDose: createdDose,
          },
        });
      }

      return createdDose;
    },

    // TODO: write tests for all eager updates
    createAdministeredDrug: async (req: CreateAdministeredDrugOp) => {
      // Intermediate request object containing clinical system ids
      // that will be resolved to hsIds at sync time

      // Eagerly created DTO
      const drugClinicalSystemId = uuidv4();

      const round = await this.storage.get(req.doseRoundClinicalSystemId);

      if (!round) throw new Error(`Could not find round while creating administered drug for ClinicalSystemId: ${req.doseRoundClinicalSystemId}`);
      if (!round.administeredDoses) throw new Error(`Could not find any administeredDoses while creating administered drug for ClinicalSystemId: ${req.doseRoundClinicalSystemId}`);


      const dose = round?.administeredDoses?.find(
        (dose) => dose.clinicalSystemId === req.administeredDoseClinicalSystemId,
      );

      if (!dose) throw new Error(`Could not find dose while creating administered drug for ClinicalSystemId: ${req.doseRoundClinicalSystemId}`);

      const createdDrug: HSAdministeredDrug = {
        ...req.request.administeredDrug,
        clinicalSystemId: drugClinicalSystemId,
        administeredDrugComments: [],
        administeredDrugOutcomes: [],
      };

      const updatedDrugs = [...(dose.administeredDrugs ?? []), createdDrug];

      const updatedRound: HSDoseRound = {
        ...round,
        // status: DoseRoundStatus.InProgress,
        administeredDoses: (round?.administeredDoses ?? []).map((d) => {
          if (d.clinicalSystemId === req.administeredDoseClinicalSystemId) {
            return {
              ...d,
              administeredDrugs: updatedDrugs,
            };
          }
          return d;
        }),
      };

      await this.storage.set(round!.clinicalSystemId!, updatedRound);

      await this.queue.unshift({
        ...req,
        request: {
          ...req.request,
          administeredDrug: createdDrug,
        },
        clinicalSystemId: drugClinicalSystemId,
      });

      return { round: updatedRound, administeredDrug: createdDrug };
    },

    createAdministeredDrugComment: async (req: CreateAdministeredDrugCommentOp) => {
      // Intermediate request object containing clinical system ids
      // that will be resolved to hsIds at sync time

      // Eagerly created DTO
      const commentClinicalSystemId = uuidv4();

      const round = await this.storage.get(req.doseRoundClinicalSystemId);

      const createdComment: HSAdministeredDrugComment = {
        ...req.request.administeredDrugComment,
        clinicalSystemId: commentClinicalSystemId,
      };

      const existingAdministeredDrug = round?.administeredDoses
        ?.flatMap((dose) => dose.administeredDrugs)
        ?.find((drug) => drug?.clinicalSystemId === req.administeredDrugClinicalSystemId);

      if (!existingAdministeredDrug) {
        throw new Error('Administered drug not found while creating administered drug comment');
      }

      const existingDose = round?.administeredDoses?.find((dose) =>
        dose.administeredDrugs?.some((drug) => drug.clinicalSystemId === req.administeredDrugClinicalSystemId),
      );
      if (!existingDose) {
        throw new Error('Could not find existing dose for drug');
      }

      const updatedDose: HSAdministeredDose = {
        ...existingDose,
        administeredDrugs: existingDose.administeredDrugs?.map((a) => {
          if (a.clinicalSystemId === existingAdministeredDrug.clinicalSystemId) {
            return { ...a, administeredDrugComments: [...(a.administeredDrugComments ?? []), createdComment] };
          }
          return a;
        }),
      };

      const updatedRound: HSDoseRound = {
        ...round!,
        administeredDoses: (round?.administeredDoses ?? []).map((administeredDose) => {
          if (administeredDose.clinicalSystemId !== existingDose?.clinicalSystemId) {
            return administeredDose;
          }
          return updatedDose;
        }),
      };

      await this.storage.set(round!.clinicalSystemId!, updatedRound);

      await this.queue.unshift({
        ...req,
        clinicalSystemId: commentClinicalSystemId,
        request: { ...req.request, administeredDrugComment: createdComment },
      });

      return updatedRound;
    },

    createAdministeredAdHocDrug: async (req: CreateAdministeredAdHocDrugOp) => {
      // Intermediate request object containing clinical system ids
      // that will be resolved to hsIds at sync time

      // Eagerly created DTO
      const drugClinicalSystemId = uuidv4();

      const round = await this.storage.get(req.doseRoundClinicalSystemId);

      const dose = round?.administeredDoses?.find(
        (dose) => dose.clinicalSystemId === req.administeredDoseClinicalSystemId,
      );

      if (!dose) {
        throw new Error('Could not find dose while creating administered ad hoc drug');
      }

      const createdDrug: HSAdministeredAdHocDrug = {
        ...req.request.administeredAdHocDrug,
        administeredAdHocDrugComments: [],
        administeredAdHocDrugOutcomes: [],
        clinicalSystemId: drugClinicalSystemId,
      };

      const updatedDrugs = [...(dose.administeredAdHocDrugs ?? []), createdDrug];

      const updatedRound: HSDoseRound = {
        ...round!,
        administeredDoses: (round?.administeredDoses ?? []).map((d) => {
          if (d.clinicalSystemId === req.administeredDoseClinicalSystemId) {
            return {
              ...d,
              administeredAdHocDrugs: updatedDrugs,
            };
          }
          return d;
        }),
      };

      await this.storage.set(round!.clinicalSystemId!, updatedRound);

      await this.queue.unshift({
        ...req,
        request: {
          ...req.request,
          administeredAdHocDrug: createdDrug,
        },
        clinicalSystemId: drugClinicalSystemId,
      });

      return { round: updatedRound, administeredDrug: createdDrug };
    },

    createAdministeredAdHocDrugComment: async (req: CreateAdministeredAdHocDrugCommentOp) => {
      // Intermediate request object containing clinical system ids
      // that will be resolved to hsIds at sync time

      // Eagerly created DTO
      const commentClinicalSystemId = uuidv4();

      const round = await this.storage.get(req.doseRoundClinicalSystemId);

      const createdComment: HSAdministeredAdHocDrugComment = {
        ...req.request.administeredAdHocDrugComment,
        clinicalSystemId: commentClinicalSystemId,
      };

      const existingAdministeredDrug = round?.administeredDoses
        ?.flatMap((dose) => dose.administeredAdHocDrugs)
        ?.find((drug) => drug?.clinicalSystemId === req.administeredDrugClinicalSystemId);

      if (!existingAdministeredDrug) {
        throw new Error('Administered drug not found while creating administered ad hoc drug comment');
      }

      const existingDose = round?.administeredDoses?.find((dose) =>
        dose.administeredAdHocDrugs?.some((drug) => drug.clinicalSystemId === req.administeredDrugClinicalSystemId),
      );
      if (!existingDose) {
        throw new Error('Could not find existing dose for ad hoc drug');
      }

      const updatedDose: HSAdministeredDose = {
        ...existingDose,
        administeredAdHocDrugs: existingDose.administeredAdHocDrugs?.map((a) => {
          if (a.clinicalSystemId === existingAdministeredDrug.clinicalSystemId) {
            return { ...a, administeredDrugComments: [...(a.administeredAdHocDrugComments ?? []), createdComment] };
          }
          return a;
        }),
      };

      const updatedRound: HSDoseRound = {
        ...round!,
        // eslint-disable-next-line sonarjs/no-identical-functions
        administeredDoses: (round?.administeredDoses ?? []).map((administeredDose) => {
          if (administeredDose.clinicalSystemId !== existingDose?.clinicalSystemId) {
            return administeredDose;
          }
          return updatedDose;
        }),
      };

      await this.storage.set(round!.clinicalSystemId!, updatedRound);

      await this.queue.unshift({
        ...req,
        clinicalSystemId: commentClinicalSystemId,
        request: { ...req.request, administeredAdHocDrugComment: createdComment },
      });

      return updatedRound;
    },

    createRoundSegment: async (req: CreateRoundSegmentOp) => {
      const segmentClinicalSystemId = uuidv4();
      req.request.doseRoundSegment.clinicalSystemId = segmentClinicalSystemId;

      const round = await this.storage.get(req.doseRoundClinicalSystemId);

      if (!round) {
        throw new Error('Could not find existing round when creating round segment');
      }

      const updatedRound = {
        ...round,
        doseRoundSegments: [...(round.doseRoundSegments ?? []), req.request.doseRoundSegment],
      };

      await this.storage.set(req.doseRoundClinicalSystemId, updatedRound);
      await this.queue.unshift(req);

      return updatedRound;
    },
    updateRoundSegment: async (req: UpdateRoundSegmentOp) => {
      const round = await this.storage.get(req.doseRoundClinicalSystemId);

      if (assertNotUndefined(round) && assertNotUndefined(round.doseRoundSegments)) {
        const newSegments = round.doseRoundSegments.map((s) =>
          s.clinicalSystemId === req.doseRoundSegmentClinicalSystemId ? req.request.roundSegment : s,
        );

        await this.storage.set(req.doseRoundClinicalSystemId, {
          ...round,
          doseRoundSegments: newSegments,
        });

        await this.queue.unshift(req);
      }

      return await this.storage.get(req.doseRoundClinicalSystemId);
    },
    createAdministeredDrugOutcome: async (req: CreateAdministeredDrugOutcomeOp) => {
      const outcomeClinicalSystemId = uuidv4();
      req.request.administeredDrugOutcome.clinicalSystemId = outcomeClinicalSystemId;
      const round = await this.storage.get(req.doseRoundClinicalSystemId);
      if (round) {
        const updatedRound: HSDoseRound = {
          ...round,
          administeredDoses:
            round.administeredDoses?.map((administeredDose) => ({
              ...administeredDose,
              administeredDrugs: administeredDose.administeredDrugs?.map((administeredDrug) => {
                if (
                  administeredDrug.hsId === req.request.hsAdministeredDrugId ||
                  administeredDrug.clinicalSystemId === req.administeredDrugClinicalSystemId
                ) {
                  return {
                    ...administeredDrug,
                    administeredDrugOutcomes: [
                      ...(administeredDrug.administeredDrugOutcomes ?? []),
                      {
                        ...req.request.administeredDrugOutcome,
                        active: true,
                      },
                    ],
                  };
                }
                return administeredDrug;
              }),
            })) ?? [],
        };

        await this.storage.set(req.doseRoundClinicalSystemId, updatedRound);
      }
      await this.queue.unshift(req);
    },
    createAdministeredAdHocDrugOutcome: async (req: CreateAdministeredAdHocDrugOutcomeOp) => {
      const adHocOutcomeClinicalSystemId = uuidv4();
      req.request.administeredAdHocDrugOutcome.clinicalSystemId = adHocOutcomeClinicalSystemId;

      const round = await this.storage.get(req.doseRoundClinicalSystemId);
      if (round) {
        const dose = round?.administeredDoses?.find((dose) =>
          dose.administeredAdHocDrugs?.some(
            (drug) => drug.clinicalSystemId === req.administeredAdHocDrugClinicalSystemId,
          ),
        );

        if (dose === undefined) {
          throw new Error('Dose not found when creating administered adhoc drug outcome');
        }

        const newRound: HSDoseRound = {
          administeredDoses: [
            {
              hsId: dose.hsId,
              clinicalSystemId: dose.clinicalSystemId,
              administeredAdHocDrugs: [
                {
                  clinicalSystemId: req.administeredAdHocDrugClinicalSystemId,
                  administeredAdHocDrugOutcomes: [
                    {
                      ...req.request.administeredAdHocDrugOutcome,
                      active: true,
                    },
                  ],
                },
              ],
            },
          ],
        };

        const updatedRound = updateRound(round, newRound);

        await this.storage.set(req.doseRoundClinicalSystemId, updatedRound);
      }
      await this.queue.unshift(req);
    },
  };

  async syncDown(facilityGroupId?: string) {
    const facilitiesToSync = await SyncUtils.getFacilitiesForGroup(facilityGroupId, this.facilitiesStore);

    /**
     * condition written to pass the JEST test for SyncRoundintegrationsTest.ts
     *
     * due to non null assertion on facility group ID, test case tries to execute roundsListRounds api on sync down
     *
     * TODO: no mock function is written for roundsListRounds at the moment
     */
    if (facilitiesToSync.length > 0) {
      const changeNumber = await SyncUtils.getChangeNumberForFacilities(
        facilitiesToSync.map((x) => x.hsId!),
        this.latestChangeNumbers,
      );
      const roundsData = await this.syncFacilityDown(
        facilityGroupId!, // non-null assertion on facility group id
        facilitiesToSync.map((facility) => facility.hsId!),
        changeNumber,
      );

      await this.storage.setMany(
        roundsData
          .map((round) => ({
            key: this.storage.get_key!(round),
            value: round,
          })),
      );

      await SyncUtils.setChangeNumberForFacilities(
          facilitiesToSync.map((x) => x.hsId!),
          this.latestChangeNumbers,
          roundsData
      );
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  private async syncFacilityDown(
    facilityGroupId: string,
    facilityIds: number[],
    changeNumber: number,
  ): Promise<HSDoseRound[]> {
    const pageSize = 200; //TODO: this can be a global variable if more API's are updated
    const rounds = await this.api.rounds.roundsListRounds(
      changeNumber,
      parseInt(facilityGroupId),
      pageSize,
    );

    const updatedRounds = await Promise.all(
      rounds.data.map(async (round: any) => {
        if (!round.clinicalSystemId) {
          round.clinicalSystemId = round.hsId!.toString();
        }
        const storedRound = round.clinicalSystemId ? await this.storage.get(round.clinicalSystemId) : undefined;
        if (storedRound && (storedRound.version ?? 0) <= (round.version ?? 0)) {
          // The round we have downloaded is newer, so replace our local copy.

          return updateRound(storedRound, round);
        }
        else if (storedRound) {
          return storedRound;
        }
        else {
          return round;
        }
      }),
    );

    //only query if result length === page size
    //if result length < page size, meaning it is last page - no need to make further api calls
    if (rounds.data.length === pageSize) {
      return [
        ...updatedRounds,
        ...(await this.syncFacilityDown(facilityGroupId, facilityIds, SyncUtils.getLatestChangeNumber(rounds.data)!)),
      ];
    }
    return updatedRounds;
  }

  //eslint-disable-next-line sonarjs/cognitive-complexity, max-lines-per-function
  async syncUp() {
    const entries = new Map<string, HSDoseRound>();
    for await (const delivery of this.queue.iterate()) {
      try {
        switch (delivery.value.type) {
          case 'round-create': {
            const storedStartedRound =
              entries.get(delivery.value.clinicalSystemId!) ??
              (await this.storage.get(delivery.value.clinicalSystemId!));
            const newRound = await this.api.rounds.roundsCreateRound(delivery.value.request);

            // fetch again in case of changes between api call
            const postApiStoredRound =
              entries.get(delivery.value.clinicalSystemId!) ??
              (await this.storage.get(delivery.value.clinicalSystemId!)) ??
              storedStartedRound;

            // TODO: Should we be setting the latest change number here instead?
            // If we don't do this, any round that has already been completed (most likely due to administering
            // an unscheduled medication) will be marked as incomplete between here, and the round completion delivery
            const newStatus =
              postApiStoredRound!.status === DoseRoundStatus.Completed
                ? postApiStoredRound!.status
                : newRound.data.status;

            entries.set(delivery.value.clinicalSystemId!, {
              ...newRound.data,
              status: newStatus,
              changeNumber: undefined,
              clinicalSystemId: delivery.value.clinicalSystemId!,
              administeredDoses: postApiStoredRound?.administeredDoses ?? [],
              doseRoundSegments: postApiStoredRound?.doseRoundSegments ?? [],
            });

            break;
          }

          case 'round-complete': {
            const storedCompletedRound =
              entries.get(delivery.value.clinicalSystemId!) ??
              (await this.storage.get(delivery.value.clinicalSystemId!));
            if (assertNotUndefined(storedCompletedRound)) {
              delivery.value.request.doseRoundId = storedCompletedRound.hsId ?? delivery.value.request.doseRoundId;
            }

            // Ignore entries with no dose round id
            if (!delivery.value.request.doseRoundId || delivery.value.request.doseRoundId <= 0) {
              console.log("Ignoring invalid dose round");
              break;
            }

            const completedRound = await this.api.rounds.roundsCompleteRound(delivery.value.request);

            // TODO: temporary patch for submitting rounds that have a local id inserted into the clinical system id but were never synced
            // This includes Dose Edge (prior system) rounds
            completedRound.data.clinicalSystemId = delivery.value.clinicalSystemId;

            // fetch again in case of changes between api call
            const postApiStoredRound =
              entries.get(delivery.value.clinicalSystemId!) ??
              (await this.storage.get(delivery.value.clinicalSystemId)) ??
              storedCompletedRound;

            entries.set(delivery.value.clinicalSystemId, {
              ...completedRound.data,
              changeNumber: undefined,
              administeredDoses: postApiStoredRound?.administeredDoses ?? [],
            });
            break;
          }

          case 'dose-create': {
            const storedDoseCreateRound =
              entries.get(delivery.value.doseRoundClinicalSystemId) ??
              (await this.storage.get(delivery.value.doseRoundClinicalSystemId));

            if (storedDoseCreateRound?.hsId === undefined) {
              throw new Error('Round id not found when creating dose');
            }

            delivery.value.request.hsDoseRoundId = storedDoseCreateRound.hsId;

            const newDose = await this.api.administeredDoses.administeredDoseCreateDose(delivery.value.request);

            // fetch again in case of changes between api call
            const postApiStoredDoseCreateRound =
              entries.get(delivery.value.doseRoundClinicalSystemId!) ??
              (await this.storage.get(delivery.value.doseRoundClinicalSystemId)) ??
              storedDoseCreateRound;
            entries.set(delivery.value.doseRoundClinicalSystemId, {
              ...postApiStoredDoseCreateRound,
              changeNumber: undefined,
              administeredDoses: [
                ...(postApiStoredDoseCreateRound.administeredDoses?.map((dose) =>
                  dose.clinicalSystemId === newDose.data.clinicalSystemId
                    ? {
                        ...newDose.data,
                        administeredDrugs: dose.administeredDrugs,
                        administeredAdHocDrugs: dose.administeredAdHocDrugs,
                      }
                    : dose,
                ) ?? []),
              ],
            });
            break;
          }

          case 'drug-create': {
            const createDrugOp = delivery.value;
            const storedDrugCreateRound =
              entries.get(delivery.value.doseRoundClinicalSystemId!) ??
              (await this.storage.get(createDrugOp.doseRoundClinicalSystemId));
            if (storedDrugCreateRound === undefined) {
              throw new Error('Round id not found when creating drug');
            }
            const dose = storedDrugCreateRound?.administeredDoses?.find(
              (dose) => dose.clinicalSystemId === createDrugOp.administeredDoseClinicalSystemId,
            );
            if (dose?.hsId === undefined) {
              throw new Error('Dose id not found when creating drug');
            }

            createDrugOp.request.hsAdministeredDoseId = dose.hsId;
            const newDrug = await this.api.administeredDrugs.administeredDrugCreateAdministeredDrug(
              createDrugOp.request,
            );

            const newCreateDrugRound: HSDoseRound = {
              clinicalSystemId: storedDrugCreateRound.clinicalSystemId,
              administeredDoses: [
                {
                  clinicalSystemId: dose.clinicalSystemId,
                  administeredDrugs: [newDrug.data],
                },
              ],
            };

            // fetch again in case of changes between api call
            const postApiStoredDrugCreateRound =
              entries.get(delivery.value.doseRoundClinicalSystemId) ??
              (await this.storage.get(createDrugOp.doseRoundClinicalSystemId));

            entries.set(
              createDrugOp.doseRoundClinicalSystemId,
              updateRound(postApiStoredDrugCreateRound ?? storedDrugCreateRound, newCreateDrugRound),
            );
            break;
          }

          case 'drug-comment-create': {
            const createCommentOp = delivery.value;
            const storedCommentCreateRound =
              entries.get(createCommentOp.doseRoundClinicalSystemId) ??
              (await this.storage.get(createCommentOp.doseRoundClinicalSystemId));
            const commentDose = storedCommentCreateRound?.administeredDoses?.find((dose) =>
              dose.administeredDrugs?.some(
                (drug) => drug.clinicalSystemId === createCommentOp.administeredDrugClinicalSystemId,
              ),
            );

            const drug = commentDose?.administeredDrugs?.find(
              (drug) => drug.clinicalSystemId === createCommentOp.administeredDrugClinicalSystemId,
            );

            if (drug === undefined) {
              throw new Error('Administered drug not found when creating comment');
            }

            if (drug?.hsId === undefined) {
              throw new Error('Administered drug id not found when creating comment, probably unsynced');
            }

            createCommentOp.request.hsAdministeredDrugId = drug.hsId;

            const newComment =
              await this.api.administeredDrugComments.administeredDrugCommentCreateAdministeredDrugComment(
                createCommentOp.request,
              );

            const newCommentRound = {
              clinicalSystemId: storedCommentCreateRound?.clinicalSystemId,
              administeredDoses: [
                {
                  clinicalSystemId: commentDose?.clinicalSystemId,
                  administeredDrugs: [
                    {
                      clinicalSystemId: drug.clinicalSystemId,
                      administeredDrugComments: [newComment.data],
                    },
                  ],
                },
              ],
            };

            // fetch again in case of changes between api call
            const postApiStoredCommentCreateRound =
              entries.get(createCommentOp.doseRoundClinicalSystemId!) ??
              (await this.storage.get(createCommentOp.doseRoundClinicalSystemId));

            entries.set(
              createCommentOp.doseRoundClinicalSystemId,
              updateRound(postApiStoredCommentCreateRound ?? storedCommentCreateRound!, newCommentRound),
            );
            break;
          }

          case 'adhoc-drug-create': {
            const createAdHocDrugOp = delivery.value;
            const storedAdHocDrugCreateRound =
              entries.get(createAdHocDrugOp.doseRoundClinicalSystemId!) ??
              (await this.storage.get(createAdHocDrugOp.doseRoundClinicalSystemId));
            const adHocDose = storedAdHocDrugCreateRound?.administeredDoses?.find(
              (dose) => dose.clinicalSystemId === createAdHocDrugOp.administeredDoseClinicalSystemId,
            );
            if (adHocDose?.hsId === undefined) {
              throw new Error('Dose id not found when creating adHoc drug');
            }

            createAdHocDrugOp.request.hsAdministeredDoseId = adHocDose.hsId;
            const newAdHocDrug = await this.api.administeredAdHocDrugs.administeredAdHocDrugCreateAdministeredAdHocDrug(
              createAdHocDrugOp.request,
            );

            // fetch again in case of changes between api call
            const postApiStoredDrugCreateRound =
              entries.get(createAdHocDrugOp.doseRoundClinicalSystemId!) ??
              (await this.storage.get(createAdHocDrugOp.doseRoundClinicalSystemId)) ??
              storedAdHocDrugCreateRound;

            entries.set(createAdHocDrugOp.doseRoundClinicalSystemId, {
              ...postApiStoredDrugCreateRound,
              changeNumber: undefined,
              administeredDoses:
                postApiStoredDrugCreateRound?.administeredDoses?.map((doseItem) =>
                  doseItem.hsId !== adHocDose.hsId
                    ? doseItem
                    : {
                        ...adHocDose,
                        administeredAdHocDrugs:
                          adHocDose.administeredAdHocDrugs?.map((drug) =>
                            drug.clinicalSystemId === newAdHocDrug.data.clinicalSystemId
                              ? {
                                  ...newAdHocDrug.data,
                                  administeredAdHocDrugComments: drug.administeredAdHocDrugComments,
                                }
                              : drug,
                          ) ?? [],
                      },
                ) ?? [],
            });
            break;
          }

          case 'adhoc-drug-comment-create': {
            const createAdHocCommentOp = delivery.value;
            const storedAdHocCommentCreateRound =
              entries.get(createAdHocCommentOp.doseRoundClinicalSystemId) ??
              (await this.storage.get(createAdHocCommentOp.doseRoundClinicalSystemId));
            const adHocCommentDose = storedAdHocCommentCreateRound?.administeredDoses?.find((dose) =>
              dose.administeredAdHocDrugs?.some(
                (drug) => drug.clinicalSystemId === createAdHocCommentOp.administeredDrugClinicalSystemId,
              ),
            );

            const adHocDrug = adHocCommentDose?.administeredAdHocDrugs?.find(
              (drug) => drug.clinicalSystemId === createAdHocCommentOp.administeredDrugClinicalSystemId,
            );

            if (adHocDrug === undefined) {
              throw new Error('Administered ad hoc drug not found when creating comment');
            }

            if (adHocDrug?.hsId === undefined) {
              throw new Error('Administered ad hoc drug id not found when creating comment, probably unsynced');
            }

            createAdHocCommentOp.request.hsAdministeredAdHocDrugId = adHocDrug.hsId;

            const newAdHocComment =
              await this.api.administeredAdHocDrugComments.administeredAdHocDrugCommentCreateAdministeredAdHocDrugComment(
                createAdHocCommentOp.request,
              );

            // Another refactoring example
            const newAdHocCommentRound = {
              clinicalSystemId: storedAdHocCommentCreateRound?.clinicalSystemId,
              administeredDoses: [
                {
                  clinicalSystemId: adHocCommentDose?.clinicalSystemId,
                  administeredAdHocDrugs: [
                    {
                      clinicalSystemId: adHocDrug.clinicalSystemId,
                      administeredAdHocDrugComments: [newAdHocComment.data],
                    },
                  ],
                },
              ],
            };

            // fetch again in case of changes between api call
            const postApiStoredRound =
              entries.get(createAdHocCommentOp.doseRoundClinicalSystemId) ??
              (await this.storage.get(createAdHocCommentOp.doseRoundClinicalSystemId)) ??
              storedAdHocCommentCreateRound;

            entries.set(
              createAdHocCommentOp.doseRoundClinicalSystemId,
              updateRound(postApiStoredRound!, newAdHocCommentRound),
            );
            break;
          }

          case 'round-segment-create': {
            const storedSegmentCreateRound =
              entries.get(delivery.value.doseRoundClinicalSystemId) ??
              (await this.storage.get(delivery.value.doseRoundClinicalSystemId));

            if (storedSegmentCreateRound?.hsId === undefined) {
              throw new Error('Round id not found');
            }

            delivery.value.request.doseRoundId = storedSegmentCreateRound.hsId;

            const newSegment = await this.api.roundSegments.roundSegmentCreate(delivery.value.request);

            // fetch again in case of changes between api call
            const postApiStoredRound =
              entries.get(delivery.value.doseRoundClinicalSystemId) ??
              (await this.storage.get(delivery.value.doseRoundClinicalSystemId)) ??
              storedSegmentCreateRound;

            entries.set(delivery.value.doseRoundClinicalSystemId, {
              ...postApiStoredRound,
              doseRoundSegments: [
                ...(postApiStoredRound.doseRoundSegments?.map((segment) =>
                  segment.clinicalSystemId === newSegment.data.clinicalSystemId ? newSegment.data : segment,
                ) ?? []),
              ],
            });
            break;
          }

          case 'round-segment-update': {
            const storedSegmentUpdateRound =
              entries.get(delivery.value.doseRoundClinicalSystemId) ??
              (await this.storage.get(delivery.value.doseRoundClinicalSystemId));

            if (storedSegmentUpdateRound?.hsId === undefined) {
              throw new Error('Round id not found');
            }

            const roundSegmentId = delivery.value.doseRoundSegmentClinicalSystemId; // for some reason typescript cannot infer this exists in the following 'find' operation

            const storedSegment = storedSegmentUpdateRound.doseRoundSegments?.find(
              (s) => s.clinicalSystemId === roundSegmentId,
            );

            // If a user ends a round before a sync cycle has completed, the HsId on both the request and the stored segment might not exist.
            // The following first queries whether the hsID exists in the store
            if (storedSegment?.hsId === undefined) {
              throw new Error('Round segment has not yet been created.');
            }
            // The request must be updated to have the hsId, otherwise syncstream returns an error.
            const updatedSegment = await this.api.roundSegments.roundSegmentUpdate({
              ...delivery.value.request,
              roundSegment: {
                ...delivery.value.request.roundSegment,
                hsId: storedSegment.hsId,
              },
            });

            // fetch again in case of changes between api call
            const postApiStoredRound =
              entries.get(delivery.value.doseRoundClinicalSystemId) ??
              (await this.storage.get(delivery.value.doseRoundClinicalSystemId)) ??
              storedSegmentUpdateRound;

            entries.set(delivery.value.doseRoundClinicalSystemId, {
              ...postApiStoredRound,
              doseRoundSegments: [
                ...(postApiStoredRound.doseRoundSegments?.map((segment) =>
                  segment.clinicalSystemId === updatedSegment.data.clinicalSystemId ? updatedSegment.data : segment,
                ) ?? []),
              ],
            });
            break;
          }

          case 'create-admin-drug-outcome': {
            const { request, administeredDrugClinicalSystemId } = delivery.value;

            // Create New Administered Drug Outcome
            const round =
              entries.get(delivery.value.doseRoundClinicalSystemId) ??
              (await this.storage.get(delivery.value.doseRoundClinicalSystemId));

            if (round === undefined) {
              throw new Error('Round undefined when syncing new outcome');
            }
            if (round.hsId === undefined) {
              throw new Error('Round HsID undefined when syncing new outcome');
            }
            const administeredDrug = round.administeredDoses
              ?.flatMap((d) => d.administeredDrugs)
              .find((ad) => (ad ? ad.clinicalSystemId === administeredDrugClinicalSystemId : false));

            if (administeredDrug === undefined) {
              throw new Error('Administered drug not found when syncing new outcome');
            }
            if (administeredDrug?.hsId === undefined) {
              throw new Error('Administered drug HsID undefined when syncing new outcome');
            }

            const newOutcome = (
              await this.api.administeredDrugOutcomes.administeredDrugOutcomeCreateAdministeredDrugOutcome({
                ...request,
                hsAdministeredDrugId: administeredDrug?.hsId,
              })
            ).data;

            const newOutcomeRound = {
              clinicalSystemId: round.clinicalSystemId,
              administeredDoses: [
                {
                  clinicalSystemId: round.administeredDoses?.find((dose) =>
                    dose.administeredDrugs?.some((drug) => drug.clinicalSystemId === administeredDrugClinicalSystemId),
                  )?.clinicalSystemId,
                  administeredDrugs: [
                    {
                      clinicalSystemId: administeredDrug.clinicalSystemId,
                      administeredDrugOutcomes: [newOutcome],
                    },
                  ],
                },
              ],
            };

            // fetch again in case of changes between api call
            const postApiStoredRound =
              entries.get(round.clinicalSystemId!) ?? (await this.storage.get(round.clinicalSystemId!)) ?? round;

            entries.set(round.clinicalSystemId!, updateRound(postApiStoredRound, newOutcomeRound));

            break;
          }

          case 'create-admin-adhoc-drug-outcome': {
            const { request, administeredAdHocDrugClinicalSystemId } = delivery.value;

            // Create New Administered Drug Outcome
            const round =
              entries.get(delivery.value.doseRoundClinicalSystemId) ??
              (await this.storage.get(delivery.value.doseRoundClinicalSystemId));

            if (round === undefined) {
              throw new Error('Round undefined when syncing new adhoc outcome');
            }
            if (round.hsId === undefined) {
              throw new Error('Round HsID when syncing new adhoc');
            }
            const administeredAdHocDrug = round.administeredDoses
              ?.flatMap((d) => d.administeredAdHocDrugs)
              .find((ad) => (ad ? ad.clinicalSystemId === administeredAdHocDrugClinicalSystemId : false));

            if (administeredAdHocDrug === undefined) {
              throw new Error('Administered drug not found when syncing new adhoc outcome');
            }
            if (administeredAdHocDrug?.hsId === undefined) {
              throw new Error('Administered drug HsID undefined when syncing new adhoc outcome');
            }

            const newOutcome = (
              await this.api.administeredAdHocDrugOutcomes.administeredAdHocDrugOutcomeCreateAdministeredAdHocDrugOutcome(
                {
                  ...request,
                  hsAdministeredAdHocDrugId: administeredAdHocDrug?.hsId,
                },
              )
            ).data;

            const newOutcomeRound = {
              clinicalSystemId: round.clinicalSystemId,
              administeredDoses: [
                {
                  clinicalSystemId: round.administeredDoses?.find((dose) =>
                    dose.administeredAdHocDrugs?.some(
                      (drug) => drug.clinicalSystemId === administeredAdHocDrugClinicalSystemId,
                    ),
                  )?.clinicalSystemId,
                  administeredAdHocDrug: [
                    {
                      clinicalSystemId: administeredAdHocDrug.clinicalSystemId,
                      administeredDrugOutcomes: [newOutcome],
                    },
                  ],
                },
              ],
            };

            // fetch again in case of changes between api call
            const postApiStoredRound =
              entries.get(round.clinicalSystemId!) ?? (await this.storage.get(round.clinicalSystemId!)) ?? round;

            entries.set(round.clinicalSystemId!, updateRound(postApiStoredRound, newOutcomeRound));

            break;
          }
        }
        await delivery.complete();
      } catch (error) {
        logger.error('Sync rounds failed', error);
        await delivery.failed();
      }
    }
    await this.storage.setMany(
      itiriri(entries.entries())
        .map((entry) => ({ key: entry[0], value: entry[1] }))
        .toArray(),
    );
  }

  async clear() {
    await this.storage.clear();
    await this.latestChangeNumbers.clear();
    await this.queue.clear();
  }

  async hasQueuedData() {
    return (await this.queue.length()) > 0;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return canUserAccessMedication;
  }
  async archive(): Promise<void> {
    const keysToDelete: string[] = [];
    for (let [k, v] of (await this.storage.all()).entries()) {
      if (await this.isStale(v)) {
        keysToDelete.push(k)
      }
    }
    await this.storage.deleteMany(keysToDelete);
  }
  setEncryptionVersion(version: number): void {
    this.storage.compressOnSave = (version > 1);
  }
  async rewrite(): Promise<void> {
    const entries: Entry<HSDoseRound>[] = [...(await this.storage.all())].map((keyValueArray) => {
      return {
        key: keyValueArray[0],
        value: keyValueArray[1]
      };
    });

    return await this.storage.setMany(entries);
  }
}
