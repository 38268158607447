/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import {
  AdHocReasonCode,
  DoseRoundStatus,
  FacilityGroupConfigurationDto,
  GroupPermissionsRes,
  HSAdministeredDose,
  HSAdministeredDrug,
  HSDoseRound,
  HSDrug,
  HSFacility,
  HSPackedMedication,
  HSPackedPatientDay,
  HSPackedPatientPrnMedication,
  HSPackedPrnMedication,
  HSPatchObservation,
  HSPatient,
  MedicationStatus,
  ReasonCode,
  SyringeDriverActivityKind,
} from 'server-openapi';
import {CreateRoundSegmentOp, SyncRounds, UpdateRoundSegmentOp} from '../SyncRounds';
import * as datefns from 'date-fns';
import {addHours} from 'date-fns';
import itiriri from 'itiriri';
import {DateUtils, Interval} from '../../core/utils/dateUtils';
import {DrugUtils, MedicationInformation} from './DrugUtils';
import {PatientUtils} from './PatientUtils';
import {isMedicationActive} from './PackedPatientDayUtils';
import {User} from 'oidc-client';
import {ResidentDetailsUtils} from './ResidentDetailsUtils';
import {
  DrugAdministrationUtils,
} from '../../pages/ResidentDetails/components/MedicationListsTabbedRouter/DrugAdministrationUtils';
import {groupBy, range} from 'lodash';
import {
  FilterDrugType,
  FilterDrugTypeControlled,
  FilterDrugTypeOther,
} from '../../pages/Dashboard/DashboardPage/DashboardPage';
import {FacilityGroupUtils} from './FacilityGroupUtils';
import {MedisphereSyringeDriverActivity} from '../SyncSyringeDriverActivity';
import {SyncTestResults} from '../SyncTestResults';
import {
  PatchStatus,
} from '../../pages/ResidentDetails/components/MedicationListsTabbedRouter/TabLists/PatchesMedicationList';
import {PatchUtils} from '../../pages/ResidentDetails/components/patches/PatchUtils';

export interface RoundUtilStores {
  packedDayStore: ReadonlyMap<string, HSPackedPatientDay>;
  patientStore: ReadonlyMap<string, HSPatient>;
  drugStore: ReadonlyMap<string, HSDrug>;
  packedPrnStore: ReadonlyMap<string, HSPackedPatientPrnMedication>;
  roundStore: ReadonlyMap<string, HSDoseRound>;
  facilityStore: ReadonlyMap<string, HSFacility>;
  facilityGroupConfigurationStore: ReadonlyMap<string, FacilityGroupConfigurationDto>;
  syringeDriverActivityStore: ReadonlyMap<string, MedisphereSyringeDriverActivity>;
  patchObservationStore: ReadonlyMap<string, HSPatchObservation>;
}

export class RoundUtils {
  private stores: RoundUtilStores;

  constructor(apiStores: RoundUtilStores) {
    this.stores = apiStores;
  }

  public canAdministerEverything = (groupPermissions: GroupPermissionsRes) => canAdministerEverything(groupPermissions);
  public canAdminister = (groupPermissions: GroupPermissionsRes, secondCheckable: boolean, patchSighting: boolean, medicationInformation: MedicationInformation) => canAdminister(groupPermissions, secondCheckable, patchSighting, medicationInformation);
  public isScheduleItemVisible = (facilityGroupId: number,
      groupPermissions: GroupPermissionsRes,
      drugStore: ReadonlyMap<string, HSDrug>,
      residentDetailsUtils: ResidentDetailsUtils,
      scheduleItem: RoundScheduleItem,
      selectedDrugTypeControlled?: FilterDrugTypeControlled,
      selectedDrugTypeOther?: FilterDrugTypeOther
  ) => isScheduleItemVisible(facilityGroupId, groupPermissions, drugStore, residentDetailsUtils, scheduleItem, selectedDrugTypeControlled, selectedDrugTypeOther);

  public isPatchSighting = (scheduleItem: RoundScheduleItem) => isPatchSighting(scheduleItem);
  public isControlledDrugId = (drugId: number) => isControlledDrugId(drugId, this.stores.drugStore);
  public getFilteredMedicationsByDrugType = (packedMedication: HSPackedMedication[], drugType?: FilterDrugType) =>
    getFilteredMedicationsByDrugType(this.stores.drugStore, packedMedication, drugType);
  public getScheduleFromParameters = (
    interval: Interval,
    facilityIds: number[]
  ) => getScheduleFromParameters(interval, facilityIds, this.stores);

  public inactiveScheduleItems = (activeRounds: HSDoseRound[], items: RoundScheduleItem[]): RoundScheduleItem[] => inactiveScheduleItems(activeRounds, items, this.stores);
  public getScheduleFromRound = (round: HSDoseRound, groupPermissions: GroupPermissionsRes, residentDetailUtils: ResidentDetailsUtils, scheduleItems: RoundScheduleItem[]) => getScheduleFromRound(round, this.stores, groupPermissions, residentDetailUtils, scheduleItems);
  public getPatientsFromRound = (round: HSDoseRound) => getPatientsFromRound(round, this.stores.patientStore);
  public getRoundsFromPatient = (patient: HSPatient) => getRoundsFromPatient(this.stores.roundStore, patient);
  public getPackedMedicationsForPatient = (controlledDrugs: boolean, patientId: number, interval: Interval) =>
    getPackedMedicationsForPatient(controlledDrugs, patientId, interval, this.stores);
  public getTimeCriticalMedicationsForPatient = (patientId: number, interval: Interval) =>
    getTimeCriticalMedicationsForPatient(patientId, interval, this.stores);
  public getRoundWindow = (date: Date, facilityGroupId: number) =>
    getRoundWindow(date, facilityGroupId, this.stores.facilityGroupConfigurationStore);
  public getMostRecentDrugInRoundByPackedMedication = getMostRecentDrugInRoundByPackedMedication;
  public getPatientsToBeSeenWithinRound = (
    round: HSDoseRound,
    roundSchedule: RoundScheduleItem[],
    requireSyringeDrivers: boolean,
  ) => getPatientsToBeSeenWithinRound(round, roundSchedule, requireSyringeDrivers);
  public getPatientsSeenWithinRound = (
    round: HSDoseRound,
    roundSchedule: RoundScheduleItem[],
    requireSyringeDrivers: boolean,
  ) => getPatientsSeenWithinRound(round, roundSchedule, this.stores, requireSyringeDrivers);
  public getRoundSummaryItems = (
    round: HSDoseRound,
    roundSchedule: RoundScheduleItem[],
    requireSyringeDrivers: boolean,
  ) => getRoundSummaryItems(round, roundSchedule, requireSyringeDrivers);
  public getPrnSummaryItems = (round: HSDoseRound) => getPrnSummaryItems(round, this.stores);
  public getNimSummaryItems = (round: HSDoseRound) => getNimSummaryItems(round, this.stores);
  public getActiveRounds = (date: Date, facilityIds: number[]) => getActiveRounds(date, facilityIds, this.stores);
  public getIdsOfFacilitiesInFacilityGroup = (facilityGroupId?: number) =>
    getIdsOfFacilitiesInFacilityGroup(facilityGroupId, this.stores.facilityStore);
  public getFacilitiesInRound = (round: HSDoseRound) => getFacilitiesInRound(round, this.stores.facilityStore);
  public getPackedMedicationStatus = (packedMedication: HSPackedMedication, patientId: number) =>
    getPackedMedicationStatus(packedMedication, patientId, this.stores.roundStore);
  public createRoundSegmentOp = createRoundSegmentOp;
  public createLeaveRoundOp = createLeaveRoundOp;
  public activeUserRounds = (userSubjectId: string, facilityGroupId: number) =>
    activeUserRounds(userSubjectId, this.stores, facilityGroupId);
  public drugInRoundWithControlledStatus = (packedMedication: HSPackedMedication[], controlled: boolean) =>
    drugInRoundWithControlledStatus(packedMedication, this.stores.drugStore, controlled);
  public getAdministeredDrugsForPackedMedication = (packedMedication: HSPackedMedication, patientId: number) =>
    getAdministeredDrugsForPackedMedication(packedMedication, patientId, this.stores.roundStore);
  public selfAdministerSubRoutine = async (params: ISelfAdministerParams) =>
    await selfAdministerSubRoutine(params, this.stores.drugStore);
  public generateScheduledActivityForSyringeDriver = (administeredDrug: HSAdministeredDrug) =>
    generateScheduledActivityForSyringeDriver(this.stores.syringeDriverActivityStore, administeredDrug);
  public generateScheduledActivityForPatch = (packedMedication: HSPackedMedication) =>
    generateScheduledActivityForPatch(
      this.stores.patchObservationStore,
      this.stores.facilityGroupConfigurationStore,
      this.stores.facilityStore,
      this.stores.patientStore,
      this.stores.packedDayStore,
      this.stores.drugStore,
      packedMedication,
    );
}

export interface RoundScheduleItem {
  patient: HSPatient;
  packedMedication: HSPackedMedication;
  scheduledActivity?: ScheduledActivity;
  scheduledActivityPending?: boolean;
}

function isPatchSighting(scheduleItem: RoundScheduleItem) {
    return scheduleItem?.scheduledActivity?.kind === PatchStatus.Sighted || scheduleItem?.scheduledActivity?.kind === PatchStatus.ToSight;
}
// determine whether a drug is S8, given its HSId
function isControlledDrugId(drugId: number, drugStore: ReadonlyMap<string, HSDrug>): boolean {
  const drug = drugStore.get(drugId.toString());

  // Find the warnings for this drug.
  const allDrugWarnings = drug !== undefined ? DrugUtils.getDrugWarnings(drug) : undefined;

  // Are any of the warnings for "controlled drugs"?
  return allDrugWarnings?.controlledDrug === true;
}
function isInsulinDrugId(drugId: number, drugStore: ReadonlyMap<string, HSDrug>): boolean {
  const drug = drugStore.get(drugId.toString());
  return drug?.isInsulin === true;
}

function getFilterTypeFromString(s?: string): FilterDrugType | undefined {
  if (!s || s === 'All') {
    return undefined;
  }
  return s as FilterDrugType;
}
function getFilteredMedicationsByDrugType(
  drugStore: ReadonlyMap<string, HSDrug>,
  packedMedication: HSPackedMedication[],
  drugType?: FilterDrugType,
): HSPackedMedication[] {
  return (
    packedMedication.filter(
      (pm) =>
        !drugType ||
        (drugType === FilterDrugType.Controlled && isControlledDrugId(pm.drugHsId!, drugStore)) ||
        (drugType === FilterDrugType.NonControlled && !isControlledDrugId(pm.drugHsId!, drugStore)) ||
        (drugType === FilterDrugType.TimeCritical && pm.timeCritical) ||
        (drugType === FilterDrugType.Insulin && isInsulinDrugId(pm.drugHsId!, drugStore)),
    ) ?? []
  );
}

function drugInRoundWithControlledStatus(packedMedication: HSPackedMedication[], drugStore: ReadonlyMap<string, HSDrug>, controlled: boolean) {
    return !!packedMedication.find((m) => !!m.drugHsId && isControlledDrugId(m.drugHsId, drugStore) === controlled);
}


function canAdministerEverything(groupPermissions: GroupPermissionsRes)
{
    return groupPermissions.canAdministerNonPackedMedicationsIncludingInjectableMedication &&
        groupPermissions.canAdministerSlidingScaleMedication &&
        groupPermissions.canAdministerShortCourseMedication &&
        groupPermissions.canAdministerControlledDrugs &&
        groupPermissions.canAdministerDoubleSigningMedication &&
        groupPermissions.canOperateSyringeDriverMedication;

}
// Indicates whether the medication can be administered.
// Note that the secondCheckable flag, will reflect the packedMedsDoNotNeedSecondCheck.
function canAdminister(groupPermissions: GroupPermissionsRes, secondCheckable: boolean, patchSighting: boolean, medicationInformation: MedicationInformation): boolean {

    if (medicationInformation.patch && patchSighting && groupPermissions.canSightPatch) {
        return true;
    }

    if (!medicationInformation.packed && medicationInformation.injectable && !groupPermissions.canAdministerNonPackedMedicationsIncludingInjectableMedication) {
        // Not allowed to administer injectable original container meds.
        return false;
    }
    if (!medicationInformation.packed && !groupPermissions.canAdministerNonPackedMedicationsIncludingInjectableMedication && !groupPermissions.canAdministerNonPackedMedicationsExcludingInjectableMedication) {
        // Not allowed to administer original container meds at all.
        return false;
    }
    if (medicationInformation.warfarin && !groupPermissions.canAdministerSlidingScaleMedication) {
        return false;
    }
    if (medicationInformation.shortCourse && !groupPermissions.canAdministerShortCourseMedication) {
        return false;
    }
    if (medicationInformation.controlled && medicationInformation.packed && !groupPermissions.canAdministerPackedControlledDrugs && !groupPermissions.canAdministerControlledDrugs) {
        return false;
    }
    if (medicationInformation.controlled && !medicationInformation.packed && !groupPermissions.canAdministerControlledDrugs) {
        return false;
    }
    if (secondCheckable && !groupPermissions.canAdministerDoubleSigningMedication) {
        return false;
    }
    if (medicationInformation.syringeDriver && !groupPermissions.canOperateSyringeDriverMedication) {
        return false;
    }

    return true;
}
function isScheduleItemVisible(facilityGroupId: number,
    groupPermissions: GroupPermissionsRes,
    drugStore: ReadonlyMap<string, HSDrug>,
    residentDetailsUtils: ResidentDetailsUtils,
    scheduleItem: RoundScheduleItem,
    selectedDrugTypeControlled?: FilterDrugTypeControlled,
    selectedDrugTypeOther?: FilterDrugTypeOther
) {
    const drug = scheduleItem.packedMedication.drugHsId
        ? drugStore.get(scheduleItem.packedMedication.drugHsId.toString())
        : undefined;
    if (!drug) {
        return false;
    }
    const medicationInformation = DrugUtils.getMedicationInformation(scheduleItem.packedMedication, drug);

    function controlledFilterVisible() {
        if (!selectedDrugTypeControlled) {
            return true;
        }
        if (selectedDrugTypeControlled === FilterDrugTypeControlled.Administrable) {
            const secondCheckable = residentDetailsUtils.isMedicationSecondCheckable(facilityGroupId, groupPermissions.packedMedsDoNotNeedSecondCheck ?? false, scheduleItem.packedMedication);
            if (canAdminister(groupPermissions, secondCheckable, isPatchSighting(scheduleItem), medicationInformation)) {
                return true;
            }
        } else if (selectedDrugTypeControlled === FilterDrugTypeControlled.Controlled) {
            if (medicationInformation.controlled) {
                return true;
            }
        } else if (!medicationInformation.controlled) {
            // Only want uncontrolled and this is controlled.
            return true;
        }
        return false;
    }
    function otherFilterVisible() {
        if (!selectedDrugTypeOther) {
            return true;
        }

        if ((selectedDrugTypeOther & FilterDrugTypeOther.TimeCritical) === FilterDrugTypeOther.TimeCritical) {
            if (medicationInformation.timeCritical) {
                return true;
            }
        }
        if ((selectedDrugTypeOther & FilterDrugTypeOther.Insulin) === FilterDrugTypeOther.Insulin) {
            if (medicationInformation.insulin) {
                return true;
            }
        }
        if ((selectedDrugTypeOther & FilterDrugTypeOther.Patch) === FilterDrugTypeOther.Patch) {
            if (medicationInformation.patch) {
                return true;
            }
        }
        if ((selectedDrugTypeOther & FilterDrugTypeOther.Injection) === FilterDrugTypeOther.Injection) {
            if (medicationInformation.injectable) {
                return true;
            }
        }
        return false;
    }


    // Each of the different filters are 'or-ed', and then the results are and-ed together.
    // e.g. if you select controlled drugs only and time critical or syringe, then you should see
    // only controlled time criticals, or controlled syringes.
    return controlledFilterVisible() && otherFilterVisible();
}
function filterScheduleItems(facilityGroupId: number,
    groupPermissions: GroupPermissionsRes,
    drugStore: ReadonlyMap<string, HSDrug>,
    residentDetailsUtils: ResidentDetailsUtils,
    scheduleItems: RoundScheduleItem[],
    selectedDrugTypeControlled?: FilterDrugTypeControlled,
    selectedDrugTypeOther?: FilterDrugTypeOther
) : RoundScheduleItem[] {

    return scheduleItems.filter((entry) => isScheduleItemVisible(facilityGroupId, groupPermissions, drugStore, residentDetailsUtils,entry, selectedDrugTypeControlled, selectedDrugTypeOther));
}
// eslint-disable-next-line max-lines-per-function
function getScheduleFromParameters(
  interval: Interval,
  facilityIds: number[],
  stores: RoundUtilStores): RoundScheduleItem[] {
  const patientUtils = new PatientUtils({
    packedDayStore: new Map(),
    roundStore: new Map(),
    packedPrnStore: new Map(),
    facilityStore: new Map(),
    drugStore: new Map(),
    testResultsStore: new Map(),
    patientStore: stores.patientStore,
    patchObservationStore: new Map(),
    facilityGroupConfigurationStore: new Map(),
    syringeDriverActivityStore: new Map(),
  });
  const residentDetailsUtils = new ResidentDetailsUtils({
    ...stores,
    secondCheckStore: new Map(),
    nimAvailableDrugStore: new Map(),
    testResultsStore: new Map(),
  });
  // the packed medications are the source of truth for the daily medication schedule.
  // get packed days for given facilities

  const patients = patientUtils
    .getActivePatients()
    .filter((patient) => !patient.suspended) //exclude offsite patients from rounds
    .toArray();

  const administeredDosesWithinInterval = itiriri(stores.roundStore.values())
    .filter((r) => facilityIds.some((id) => r.administeredDoses?.some((d) => d.facilityId === id)))
    .toArray()
    .flatMap((r) => r.administeredDoses ?? [])
    .filter((d) => !!d.doseTimestamp && DateUtils.isTimeBetween(d.doseTimestamp, interval));

  const administeredMedicationIdsInInterval = administeredDosesWithinInterval.flatMap((d) =>
    d.administeredDrugs
      ? d.administeredDrugs.map((drug) => {
          return { medicationId: drug.medicationId, doseTimeStamp: d.doseTimestamp };
        })
      : [],
  );

  const signingSheetMedicationIds = patients
    .flatMap((patient) => patient.patientProfiles)
    .flatMap((profile) => [...(profile?.allCurrentMedications ?? []), ...(profile?.recentlyDeletedMedications ?? [])])
    .filter((med) => med.showOnSigningSheet)
    .map((med) => med?.hsId);

  const packedDays = itiriri(stores.packedDayStore.values())
    .filter(
      (day) =>
        !!day.packDate &&
        datefns.subDays(datefns.startOfDay(DateUtils.toDate(interval.start)), 14) <=
          DateUtils.toOffsetlessDate(day.packDate),
    )
    .map((day) => ({
      ...day,
      packedMedications: day.packedMedications?.filter((med) => signingSheetMedicationIds.includes(med.medicationId)),
    }))
    .toArray();

  const currentPackedMedications = packedDays
    .filter(
      (day) =>
        day.packDate && datefns.isSameDay(DateUtils.toOffsetlessDate(day.packDate), DateUtils.toDate(interval.start)),
    )
    .flatMap((day) => day.packedMedications ?? []);

  const previousDayPackedMedications = packedDays
    .filter(
      (day) =>
        day.packDate &&
        datefns.isSameDay(
          DateUtils.toOffsetlessDate(day.packDate),
          datefns.subDays(DateUtils.toDate(interval.start), 1),
        ),
    )
    .flatMap((day) => day.packedMedications ?? []);

  const lastTwoPackedDayMedications = [...currentPackedMedications, ...previousDayPackedMedications];

  // Get all syringe drivers in the last 2 days
  const recentSyringes = lastTwoPackedDayMedications.filter((med) => med.route?.code === 'SID');

  const activePatches = patients.flatMap((patient) =>
    residentDetailsUtils.getActivePatches(
      patient.hsId!,
      DateUtils.toDate(interval.start),
      packedDays.filter((day) => day.patientId === patient.hsId),
    ),
  );
  return (
    itiriri(packedDays)
      .filter((m) => m.facilityId !== undefined && facilityIds.includes(m.facilityId))
      .toArray()
      // map to defined packedMedication list and flatten result
      .flatMap(
        // eslint-disable-next-line sonarjs/cognitive-complexity
        (p) =>
          p.packedMedications?.flatMap((m) => {
            const scheduledPatchActivity = activePatches.some((med) => m.hsId === med.hsId)
              ? generateScheduledActivityForPatch(
                  stores.patchObservationStore,
                  stores.facilityGroupConfigurationStore,
                  stores.facilityStore,
                  stores.patientStore,
                  stores.packedDayStore,
                  stores.drugStore,
                  m,
                ).sort((a, b) => DateUtils.compareDatesDescending(a.time, b.time))
              : undefined;

            const scheduledSyringeDriverActivity = recentSyringes.some((med) => m.hsId === med.hsId)
              ? generateSyringeDriverActivityFromPackedMedication(
                  m,
                  residentDetailsUtils,
                  stores.syringeDriverActivityStore,
                )
              : undefined;
            return [
              {
                patientId: p.patientId!,
                packedMedication: m,
                scheduledActivity: undefined,
                scheduledActivityPending: undefined,
              },
              ...(scheduledPatchActivity
                ?.filter(
                  (activity, index) =>
                    index ===
                      scheduledPatchActivity.findIndex(
                        (act) =>
                          act.kind === PatchStatus.Sighted &&
                          DateUtils.isTimeBetween(DateUtils.fromDate(act.time), interval),
                      ) || activity.kind !== PatchStatus.Sighted,
                )
                .map((activity) => ({
                  patientId: p.patientId!,
                  packedMedication: m,
                  scheduledActivity: activity,
                  scheduledActivityPending:
                    !activity.patchActivity &&
                    !scheduledPatchActivity.some(
                      (otherActivity) => otherActivity.kind === PatchStatus.Removed && otherActivity.patchActivity,
                    ),
                })) ?? []),
              ...(scheduledSyringeDriverActivity
                ?.filter(
                  (act) =>
                    DateUtils.isTimeBetween(DateUtils.fromDate(act.time), interval) &&
                    (!act.syringeActivity ||
                      !scheduledSyringeDriverActivity.some(
                        (otherAct) =>
                          otherAct.syringeActivity &&
                          (otherAct.kind === SyringeDriverActivityKind.Cease ||
                            otherAct.kind === SyringeDriverActivityKind.Stop),
                      )),
                )
                .map((activity) => ({
                  patientId: p.patientId!,
                  packedMedication: m,
                  scheduledActivity: activity,
                  scheduledActivityPending:
                    !activity.syringeActivity &&
                    !scheduledSyringeDriverActivity.some(
                      (otherActivity) =>
                        (otherActivity.kind === SyringeDriverActivityKind.Stop ||
                          otherActivity.kind === SyringeDriverActivityKind.Cease) &&
                        otherActivity.syringeActivity,
                    ),
                })) ?? []),
            ];
          }) ?? [],
      )
      .filter((s) => {
        const drug = s.packedMedication.drugHsId
          ? stores.drugStore.get(s.packedMedication.drugHsId.toString())
          : undefined;
        return isMedicationActive(s.packedMedication) && !(drug && PatchUtils.isPatchRemoval(drug));
      })
      // medication within time
      .filter((s) => {
        const time = s.scheduledActivity?.time
          ? DateUtils.fromDate(s.scheduledActivity.time)
          : s.packedMedication.doseTimestamp;
        return !!time && DateUtils.isTimeBetween(time, interval);
      })
      // medication not already administered
      .filter(
        (s) =>
          s.scheduledActivity ||
          (s.packedMedication.medicationId !== undefined &&
            !administeredMedicationIdsInInterval.some(
              (m) =>
                m.medicationId === s.packedMedication.medicationId &&
                m.doseTimeStamp === s.packedMedication.doseTimestamp,
            )),
      )

      .filter((s) => {
        //only check pending for patch activities - NOT syringe activity
        if (s.scheduledActivity && !(s.scheduledActivity.kind in SyringeDriverActivityKind)) {
          return s.scheduledActivityPending;
        }
        return true;
      })
      // This step maps the patientIds to HSPatients
      .flatMap((s) => {
        const patient = patients.find((p) => p.hsId === s.patientId);

        return patient !== undefined
          ? [
              {
                patient: patient,
                packedMedication: s.packedMedication,
                scheduledActivity: s.scheduledActivity,
                scheduledActivityPending: s.scheduledActivityPending,
              },
            ]
          : [];
      })
  );
}

function scheduleItemMatchesFilter(item: RoundScheduleItem,
    filterType: FilterDrugType,
    stores: RoundUtilStores): boolean {
    const drug = stores.drugStore.get(item.packedMedication?.drugHsId?.toString() ?? "-1");
    if (!drug) {
      return false;
    }
    if (filterType === FilterDrugType.Controlled) {
      return DrugUtils.getDrugWarnings(drug).controlledDrug;
    }
    if (filterType === FilterDrugType.NonControlled) {
      return !DrugUtils.getDrugWarnings(drug).controlledDrug;
    }
    if (filterType === FilterDrugType.TimeCritical) {
      return item.packedMedication?.timeCritical ?? false;
    }
    if (filterType === FilterDrugType.Insulin) {
      return drug.isInsulin ?? false;
    }
    // Should never get here.
    return false;

}

// eslint-disable-next-line sonarjs/cognitive-complexity
function compareScheduleItemWithAdministeredDose(item: RoundScheduleItem, administeredDose: HSAdministeredDose, filter: FilterDrugType | undefined, stores: RoundUtilStores) {
  // Dose the patient match.
  if (item.patient.hsId !== administeredDose.patientId) {
    return false;
  }

  // Dose the time match.
  if (!item.packedMedication.doseTimestamp && !item.packedMedication.doseTimestamp) {
    return true;
  }
  if (!item.packedMedication.doseTimestamp || !item.packedMedication.doseTimestamp) {
    return false;
  }
  if (!administeredDose?.doseTimestamp) {
    return false;
  }
  const tm = item.scheduledActivity?.time ?? DateUtils.toOffsetlessDate(item.packedMedication.doseTimestamp!);
  if (DateUtils.compareDates(DateUtils.toOffsetlessDate(administeredDose.doseTimestamp!), tm) !== 0) {
    return false;
  }
  if (!filter) {
    // No filter, so it mathces.
    return true;
  }

  // Filter specified.  Check that it matches.
  return scheduleItemMatchesFilter(item, filter, stores);
}

function inactiveScheduleItems(activeRounds: HSDoseRound[], items: RoundScheduleItem[], stores: RoundUtilStores): RoundScheduleItem[] {
  const scheduleItemsInActiveRound: RoundScheduleItem[] = [];
  for(const round of activeRounds) {
      const filterType = getFilterTypeFromString(round.description ?? "All");
      for(const administeredDose of round.administeredDoses ?? []) {
        scheduleItemsInActiveRound.push(...items.filter(x => compareScheduleItemWithAdministeredDose(x, administeredDose, filterType, stores)));
      }
  }
  // Now remove all of the items that are in active rounds.
  return items.filter(x => !scheduleItemsInActiveRound.includes(x));
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function getScheduleFromRound(round: HSDoseRound, stores: RoundUtilStores, groupPermissions: GroupPermissionsRes, residentDetailUtils: ResidentDetailsUtils, scheduleItems: RoundScheduleItem[]): RoundScheduleItem[] {

  //Get the Filters and convert it to their types
  const roundFilterDrugTypeControlled = getRoundFilterDrugTypeControlled(round);
  const roundFilterDrugTypeOther = getRoundFilterDrugTypeOther(round);

  const facilityGroupId = getFacilityGroupIdByFacilityId(round.facilityId!, stores);
  const interval = getRoundWindow(
    DateUtils.toDate(round.createdAt!),
    facilityGroupId,
    stores.facilityGroupConfigurationStore,
  );
  const facilityIds = round.administeredDoses?.flatMap((d) => (d.facilityId !== undefined ? [d.facilityId] : []));
  const patientIds = round.administeredDoses?.flatMap((d) => (d.patientId !== undefined ? [d.patientId] : []));
  // map the already administered drugs to a schedule.
  const alreadyAdministered = round.administeredDoses
    ? round.administeredDoses.flatMap((dose) => getScheduleFromDose(dose, stores))
    : [];

  const toBeAdministered = filterScheduleItems(facilityGroupId, groupPermissions, stores.drugStore, residentDetailUtils, scheduleItems, roundFilterDrugTypeControlled, roundFilterDrugTypeOther)
        .filter((x) => {
            // The nurse can now pick individual dose times to administer.
            // This filter matches the available round dose times with those selected by the nurse.
            if (!round.administeredDoses) {
              return true;
            }
            const doseTime = x.scheduledActivity?.time ?? DateUtils.toOffsetlessDate(x.packedMedication!.doseTimestamp!);
            return round.administeredDoses.some((y) => {
              return (
                y.patientId === x.patient.hsId &&
                y.doseTimestamp && // PRN's don't have a timestamp.
                DateUtils.compareDates(DateUtils.toOffsetlessDate(y.doseTimestamp!), doseTime) === 0
              );
            });
          });

  return alreadyAdministered.concat(toBeAdministered);
}

function getScheduleFromDose(dose: HSAdministeredDose, stores: RoundUtilStores): RoundScheduleItem[] {
  const patientUtils = new PatientUtils({
    packedDayStore: new Map(),
    roundStore: new Map(),
    packedPrnStore: new Map(),
    facilityStore: new Map(),
    drugStore: new Map(),
    testResultsStore: new Map(),
    patientStore: stores.patientStore,
    patchObservationStore: new Map(),
    facilityGroupConfigurationStore: new Map(),
    syringeDriverActivityStore: new Map(),
  });

  const patient = patientUtils.getActivePatients().find((p) => p.hsId === dose.patientId);
  if (!patient || !dose.administeredDrugs) {
    return [];
  }
  const medicationIds = dose.administeredDrugs.flatMap((d) => (d.medicationId ? [d.medicationId] : []));
  const packedMedications = itiriri(stores.packedDayStore.values())
    .filter((p) => p.patientId === dose.patientId)
    .flat((p) => p.packedMedications ?? [])
    .filter((p) => medicationIds.includes(p.medicationId!) && dose.doseTimestamp === p.doseTimestamp)
    .toArray();

  return packedMedications.map((m) => {
    return {
      patient: patient,
      packedMedication: m,
    };
  });
}

function getPatientsFromRound(round: HSDoseRound, patientStore: ReadonlyMap<string, HSPatient>): HSPatient[] {
  const patientUtils = new PatientUtils({
    packedDayStore: new Map(),
    roundStore: new Map(),
    packedPrnStore: new Map(),
    facilityStore: new Map(),
    drugStore: new Map(),
    testResultsStore: new Map(),
    patientStore: patientStore,
    patchObservationStore: new Map(),
    facilityGroupConfigurationStore: new Map(),
    syringeDriverActivityStore: new Map(),
  });
  return patientUtils
    .getActivePatients()
    .filter(
      (p) =>
        round.administeredDoses !== undefined &&
        round.administeredDoses !== null &&
        round.administeredDoses.map((d) => d.patientId).includes(p.hsId),
    )
    .toArray();
}

function getRoundsFromPatient(roundStore: ReadonlyMap<string, HSDoseRound>, patient: HSPatient): HSDoseRound[] {
  return itiriri(roundStore.values())
    .filter(
      (r) =>
        !!r.administeredDoses &&
        r.administeredDoses.length > 0 &&
        r.administeredDoses.map((d) => d.patientId).includes(patient.hsId),
    )
    .sort((a, b) => DateUtils.toDate(a.createdAt!).getTime() - DateUtils.toDate(b.createdAt!).getTime())
    .toArray();
}

function getPackedMedicationAdminstrationReason(
  packedMedication: HSPackedMedication,
  round: HSDoseRound,
): ReasonCode | undefined {
  if (!isMedicationActive(packedMedication)) {
    return ReasonCode.Ceased;
  }

  const drugsWithTimes = round.administeredDoses!.flatMap((dose) =>
    dose.administeredDrugs && dose.doseTimestamp
      ? dose!.administeredDrugs!.map((d) => {
          return { drug: d, time: dose.doseTimestamp! };
        })
      : [],
  );

  return drugsWithTimes.find(
    (drugTime) =>
      drugTime.drug.medicationId === packedMedication.medicationId! && drugTime.time === packedMedication.doseTimestamp,
  )?.drug.reasonCode;
}

export interface PackedSummaryItem {
  patient: HSPatient;
  packedMedication: HSPackedMedication;
  reason?: ReasonCode;
}

function evaluateScheduleActivityReasonCode(scheduleItem: RoundScheduleItem, requireSyringeDrivers: boolean) {
  //if activity is not pending assume it is dosed
  if (scheduleItem.scheduledActivity && !scheduleItem.scheduledActivityPending) {
    return ReasonCode.Dosed;
  }
  //if the activity is pending AND if that activity is a syringe driver observation -> check requireSyringeDriver flag
  if (
    scheduleItem.scheduledActivity &&
    scheduleItem.scheduledActivityPending &&
    scheduleItem.scheduledActivity.kind in SyringeDriverActivityKind
  ) {
    return requireSyringeDrivers ? undefined : ReasonCode.Dosed;
  }
  return undefined;
}

function getRoundSummaryItems(
  round: HSDoseRound,
  roundSchedule: RoundScheduleItem[],
  requireSyringeDrivers: boolean,
): PackedSummaryItem[] {
  return roundSchedule.map((scheduleItem) => {
    return {
      ...scheduleItem,
      reason: scheduleItem.scheduledActivity
        ? evaluateScheduleActivityReasonCode(scheduleItem, requireSyringeDrivers)
        : getPackedMedicationAdminstrationReason(scheduleItem.packedMedication, round),
    };
  });
}

export interface NimSummaryItem {
  patient: HSPatient;
  drugId: number;
  reason?: AdHocReasonCode;
}

function getNimSummaryItems(round: HSDoseRound, stores: RoundUtilStores): NimSummaryItem[] {
  const patientUtils = new PatientUtils({
    packedDayStore: new Map(),
    roundStore: new Map(),
    packedPrnStore: new Map(),
    facilityStore: new Map(),
    drugStore: new Map(),
    testResultsStore: new Map(),
    patientStore: stores.patientStore,
    patchObservationStore: new Map(),
    facilityGroupConfigurationStore: new Map(),
    syringeDriverActivityStore: new Map(),
  });

  if (!round.administeredDoses) {
    return [];
  }
  const nimsAdministeredPerPatient = round.administeredDoses.flatMap((dose) => {
    return {
      patientId: dose.patientId!,
      adHocDrugs: dose.administeredAdHocDrugs,
    };
  });
  return nimsAdministeredPerPatient.flatMap((patientAdHocDrugs) => {
    const patient = patientUtils.getActivePatients().find((p) => p.hsId === patientAdHocDrugs.patientId);
    if (patient && patientAdHocDrugs.adHocDrugs) {
      return patientAdHocDrugs.adHocDrugs?.map((d) => {
        return { patient: patient, drugId: d.drugId!, reason: d.reasonCode };
      });
    } else {
      return [];
    }
  });
}
export interface PrnSummaryItem {
  patient: HSPatient;
  packedPrnMedication: HSPackedPrnMedication;
  reason?: ReasonCode;
}

function getPrnSummaryItems(round: HSDoseRound, stores: RoundUtilStores): PrnSummaryItem[] {
  const patientUtils = new PatientUtils({
    packedDayStore: new Map(),
    roundStore: new Map(),
    packedPrnStore: new Map(),
    facilityStore: new Map(),
    drugStore: new Map(),
    testResultsStore: new Map(),
    patientStore: stores.patientStore,
    patchObservationStore: new Map(),
    facilityGroupConfigurationStore: new Map(),
    syringeDriverActivityStore: new Map(),
  });

  if (!round.administeredDoses) {
    return [];
  }
  const prnsAdministeredPerPatient = round.administeredDoses.flatMap((dose) => {
    return {
      patientId: dose.patientId!,
      prnDrugs: dose.administeredDrugs?.filter((drug) => getPrnMedication(drug.medicationId!, stores) !== undefined),
    };
  });

  return prnsAdministeredPerPatient.flatMap((patientPrns) => {
    const patient = patientUtils.getActivePatients().find((p) => p.hsId === patientPrns.patientId);
    if (patient && patientPrns.prnDrugs) {
      return patientPrns.prnDrugs?.map((d) => {
        return {
          patient: patient,
          packedPrnMedication: getPrnMedication(d.medicationId!, stores)!,
          reason: d.reasonCode,
        };
      });
    } else {
      return [];
    }
  });
}

function getPrnMedication(medicationId: number, stores: RoundUtilStores): HSPackedPrnMedication | undefined {
  return itiriri(stores.packedPrnStore.values())
    .toArray()
    .flatMap((patientPrns) => patientPrns.packedPrnMedications ?? [])
    .find((prn) => prn.medicationId === medicationId);
}

function getPatientsToBeSeenWithinRound(
  round: HSDoseRound,
  roundSchedule: RoundScheduleItem[],
  requireSyringeDrivers: boolean,
): HSPatient[] {
  const summary = getRoundSummaryItems(round, roundSchedule, requireSyringeDrivers);
  return [
    ...new Set(
      summary.filter((summaryItem) => summaryItem.reason === undefined).map((summaryItem) => summaryItem.patient),
    ),
  ];
}

function getPatientsSeenWithinRound(
  round: HSDoseRound,
  roundScheduleItem: RoundScheduleItem[],
  stores: RoundUtilStores,
  requireSyringeDrivers: boolean,
): HSPatient[] {
  const allPatientsInRound = getPatientsFromRound(round, stores.patientStore);
  const patientsToBeSeenInRound = getPatientsToBeSeenWithinRound(round, roundScheduleItem, requireSyringeDrivers);
  return allPatientsInRound.filter((patient) => !patientsToBeSeenInRound.find((p) => p.hsId === patient.hsId));
}

function getPackedMedicationsForPatient(
  controlledDrugs: boolean,
  patientId: number,
  interval: Interval,
  stores: RoundUtilStores,
): HSPackedMedication[] {
  // the packed medications are the source of truth for the daily medication schedule.
  // get packed days for given facilities
  const patient = stores.patientStore.get(patientId.toString());
  return (
    itiriri(stores.packedDayStore.values())
      .filter((m) => m.patientId === patientId)
      .toArray()
      // map to defined packedMedication list and flatten result
      .flatMap((p) => p.packedMedications ?? [])
      .filter((p) =>
        patient?.patientProfiles?.some(
          (profile) =>
            [...(profile.allCurrentMedications ?? []), ...(profile.recentlyDeletedMedications ?? [])].find(
              (med) => med.hsId === p.medicationId,
            )?.showOnSigningSheet,
        ),
      )
      // filter out deleted items
      .filter(isMedicationActive)
      // medication within time
      .filter((m) => !!m.doseTimestamp && DateUtils.isTimeBetween(m.doseTimestamp, interval))
      // give all drugs if `controlledDrugs`, otherwise return only controlled drugs
      .filter((m) => !controlledDrugs || (m.drugHsId !== undefined && isControlledDrugId(m.drugHsId, stores.drugStore)))
  );
}

function getTimeCriticalMedicationsForPatient(
  patientId: number,
  interval: Interval,
  stores: RoundUtilStores,
): HSPackedMedication[] {
  return getPackedMedicationsForPatient(false, patientId, interval, stores).filter((m) => m.timeCritical);
}

// TODO: This should be configurable by the facility.
function getRoundWindow(
  date: Date,
  facilityGroupId: number,
  facilityGroupConfigurationStore: ReadonlyMap<string, FacilityGroupConfigurationDto>,
): Interval {
  const facilityGroupUtils = new FacilityGroupUtils({
    facilityGroupConfigurationStore: facilityGroupConfigurationStore,
  });
  const intervalMinutes = facilityGroupUtils.getRoundIntervalsInMinutes(facilityGroupId);
  const startDate = datefns.subMinutes(date, intervalMinutes);
  const endDate = datefns.addMinutes(date, intervalMinutes);

  const start = DateUtils.fromDate(startDate);
  const end = DateUtils.fromDate(endDate);

  return {
    start,
    end,
  };
}

function getRoundFilterDrugTypeControlled(round: HSDoseRound) {
  if (round.description?.includes('Non-Controlled')) {
    return FilterDrugTypeControlled.NonControlled
  } else if (round.description?.includes('Controlled'))
  {
    return FilterDrugTypeControlled.Controlled;
  } else if (round.description?.includes('Administrable')) {
      return FilterDrugTypeControlled.Administrable;
  }
  return undefined;
}

function getRoundFilterDrugTypeOther(round: HSDoseRound) {
  let roundFilterDrugTypeOther = 0;
  //console.log('round.description: ' + round.description);

  //console.log('round.description?.includes(\'TimeCritical\'):' + round.description?.includes('TimeCritical'))

  //console.log('round.description?.includes(\'Insulin\'):' + round.description?.includes('Insulin'));

  if (round.description?.includes('TimeCritical')) {
    roundFilterDrugTypeOther = (roundFilterDrugTypeOther | FilterDrugTypeOther.TimeCritical);
  }
  if (round.description?.includes('Insulin'))
  {
    roundFilterDrugTypeOther = (roundFilterDrugTypeOther | FilterDrugTypeOther.Insulin);
  }
  if (round.description?.includes('Patch')) {
      roundFilterDrugTypeOther = (roundFilterDrugTypeOther | FilterDrugTypeOther.Patch);
  }
  if (round.description?.includes('Injection')) {
      roundFilterDrugTypeOther = (roundFilterDrugTypeOther | FilterDrugTypeOther.Injection);
  }

  //console.log('roundFilterDrugTypeOther:' + roundFilterDrugTypeOther);
  return roundFilterDrugTypeOther === 0 ? undefined : roundFilterDrugTypeOther;
}

function getMostRecentDrugInRoundByPackedMedication(
  packedMedication: HSPackedMedication,
  patientId: number,
  round?: HSDoseRound,
): HSAdministeredDrug | undefined {
  const dose = round?.administeredDoses?.find(
    (dose) => dose.doseTimestamp === packedMedication.doseTimestamp && dose.patientId === patientId,
  );

  return dose?.administeredDrugs
    ?.sort((a, b) => DateUtils.compareDateStringsDescending(a.administeredAt, b.administeredAt))
    .find((drug) => drug.medicationId === packedMedication.medicationId);
}

function getActiveRounds(date: Date, facilityIds: number[], roundUtilStore: RoundUtilStores): HSDoseRound[] {
  const windowStart = addHours(new Date(), -2);
  return itiriri(roundUtilStore.roundStore.values())
    .filter(
      (r) =>
        r.status !== undefined &&
        !!r.createdAt &&
        (r.status === DoseRoundStatus.InProgress ||
          r.status === DoseRoundStatus.Pending ||
          r.status === DoseRoundStatus.Paused) &&
        facilityIds.some(
          (id) =>
            r.administeredDoses !== undefined &&
            r.administeredDoses !== null &&
            r.administeredDoses.flatMap((d) => d.facilityId).includes(id),
        ) &&
          // Returns +ve if window start is before the round start.
        DateUtils.compareDates(windowStart, DateUtils.toDate(r.createdAt)) > 0,
    )
    .toArray();
}

function getIdsOfFacilitiesInFacilityGroup(
  facilityGroupId: number | undefined,
  store: ReadonlyMap<string, HSFacility>,
): number[] {
  return itiriri(store.values())
    .filter((f) => facilityGroupId !== undefined && f.hsId !== undefined && f.facilityGroupId === facilityGroupId)
    .map((f) => f.hsId!)
    .toArray();
}

function getFacilitiesInRound(round: HSDoseRound, store: ReadonlyMap<string, HSFacility>): HSFacility[] {
  const facilityIds = round.administeredDoses?.map((d) => d.facilityId);
  return itiriri(store.values())
    .filter((f) => f.hsId !== undefined && facilityIds !== undefined && facilityIds.includes(f.hsId))
    .toArray();
}

function getPackedMedicationStatus(
  packedMedication: HSPackedMedication,
  patientId: number,
  store: ReadonlyMap<string, HSDoseRound>,
): ReasonCode | undefined {
  if (packedMedication.medicationStatus === MedicationStatus.Deleted || packedMedication.interimDeleted) {
    return ReasonCode.Ceased;
  }

  let administeredDrugs: HSAdministeredDrug[] = [];

  // Find all the rounds that match.
  itiriri(store.values()).forEach((round) => {
    // Find the doses that match the required time.
    round?.administeredDoses?.filter((dose) => dose.doseTimestamp === packedMedication.doseTimestamp).forEach((dose) => {
      // Find the meds that match the required med.
      dose.administeredDrugs?.filter((dr) => dr.medicationId === packedMedication.medicationId)?.forEach((drug) => {
        if (drug.medicationId === packedMedication.medicationId) {
          administeredDrugs.push(drug);
        }
      })
    });
  });

  if (administeredDrugs.length == 0) {
    return undefined;
  }
  // Return the reason code of the most recent one.
  const mostRecentDose = administeredDrugs?.sort((a, b) => DateUtils.compareDateStringsDescending(a.administeredAt, b.administeredAt));
  return mostRecentDose[0].reasonCode;
}

// TODO: remove or user everywhere
function createRoundSegmentOp(
  facilityGroupId: number,
  doseRoundClinicalSystemId: string,
  userSubjectId: string,
): CreateRoundSegmentOp {
  const joinTime = DateUtils.fromDate(new Date());
  return {
    type: 'round-segment-create',
    doseRoundClinicalSystemId: doseRoundClinicalSystemId,
    request: {
      doseRoundId: -1,
      doseRoundStatus: DoseRoundStatus.InProgress,
      doseRoundSegment: {
        createdAt: joinTime,
        startedAt: joinTime,
        lastUpdatedAt: joinTime,
        startCommentText: 'Joined',
        active: true,
        startedBySubjectId: userSubjectId,
        //startedById: <resolved from mapping table on MRS>
        // endCommentText:
      },
      facilityGroupId: facilityGroupId,
    },
  };
}

function createLeaveRoundOp(
  doseRoundClinicalSystemId: string,
  facilityGroupId: number,
  userSubjectId: string,
  round: HSDoseRound,
  finishRoundReason?: string
): UpdateRoundSegmentOp | undefined {
  const endTime = DateUtils.fromDate(new Date());

  const roundSegment = round.doseRoundSegments?.find(
    (s) => s.startedBySubjectId === userSubjectId && !s.endedBySubjectId,
  );

  if (!roundSegment?.clinicalSystemId) {
    return undefined;
  }


  return {
    type: 'round-segment-update',
    doseRoundClinicalSystemId: doseRoundClinicalSystemId,
    doseRoundSegmentClinicalSystemId: roundSegment.clinicalSystemId,
    request: {
      roundSegment: {
        ...roundSegment,
        endedBySubjectId: userSubjectId,
        endedAt: endTime,
        endCommentText: finishRoundReason? finishRoundReason : 'Left',
        lastUpdatedAt: endTime,
        active: false,
      },
      // Note: round.status is always defined, it is not optional in healthstream, and it is required for this request type.
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      roundStatus: round?.status!,
      facilityGroupId: facilityGroupId,
    },
  };
}

function activeUserRounds(userSubjectId: string, roundUtilStore: RoundUtilStores, facilityGroupId: number) {
  const windowStart = addHours(new Date(), -2);
  return itiriri(roundUtilStore.roundStore.values())
    .filter(
      (r) =>
        r.createdAt !== undefined &&
        (r.status === DoseRoundStatus.InProgress ||
          r.status === DoseRoundStatus.Pending ||
          r.status === DoseRoundStatus.Paused) &&
        r.doseRoundSegments !== undefined &&
        r.doseRoundSegments !== null &&
        r.doseRoundSegments.some(
          (s) =>
            (s.endedBySubjectId === undefined || s.endedBySubjectId === null) && s.startedBySubjectId === userSubjectId,
        ) &&
          // Returns +ve if window start is before the round start.
          DateUtils.compareDates(windowStart, DateUtils.toDate(r.createdAt)) > 0,
    )
    .toArray();
}

function getAdministeredDrugsForPackedMedication(
  packedMedication: HSPackedMedication,
  patientId: number,
  store: ReadonlyMap<string, HSDoseRound>,
) {
  const doses = itiriri(store.values())
    .flat((round) => round.administeredDoses ?? [])
    .filter((dose) => dose.doseTimestamp === packedMedication.doseTimestamp && dose.patientId === patientId);

  return doses
    .flat((dose) => dose.administeredDrugs ?? [])
    .filter((drug) => drug.medicationId === packedMedication.medicationId)
    .toArray();
}

function getFacilityGroupIdByFacilityId(facilityId: number, stores: RoundUtilStores) {
  const facility = stores.facilityStore.get(facilityId.toString());
  // It should be safe enough to assume that the facility group ID always exists
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  return facility?.facilityGroupId!;
}

interface ISelfAdministerParams {
  roundSchedule: RoundScheduleItem[];
  drugList: HSDrug[];
  user: User;
  residentDetailsUtils: ResidentDetailsUtils;
  facilityGroupId: string;
  syncRounds: SyncRounds;
  syncTestResults: SyncTestResults;
  round: HSDoseRound;
}

async function selfAdministerSubRoutine(params: ISelfAdministerParams, drugStore: ReadonlyMap<string, HSDrug>) {
  const { roundSchedule, drugList, user, residentDetailsUtils, facilityGroupId, syncRounds, syncTestResults, round } =
    params;

  const groupedRoundScheduleItems = Object.values(groupBy(roundSchedule, (scheduleItem) => scheduleItem.patient.hsId));
  //run a loop for all the medications of the FSA patient
  for (const scheduleItem of groupedRoundScheduleItems) {
    const packedMedications = scheduleItem.map((item) => item.packedMedication);

    for (const packedMedication of packedMedications) {
      const drug = drugStore.get(packedMedication.drugHsId!.toString());
      if (scheduleItem[0].patient && isMedicationSelfAdministerable(packedMedication, scheduleItem[0].patient, drug!)) {
        await selfAdministerMedication(
          packedMedication,
          drugList,
          residentDetailsUtils,
          syncRounds,
          syncTestResults,
          round,
          user,
          scheduleItem[0].patient,
          facilityGroupId,
        );
      }
    }
  }
}

/**
 * this function is used to check if a medication should be self administered by the application based on certain parameters
 * not to be confused by the function isMedicationSelfAdministered from PackedPatientDayUtils
 * which checks the selfAdministered property of an HSMedication object
 */
function isMedicationSelfAdministerable(packedMedication: HSPackedMedication, patient: HSPatient, drug: HSDrug) {
  //check if packed medication has a corresponding HSMedication object to track changes
  const hsMedicationObject = patient.patientProfiles
    ?.flatMap((profile) => profile.allCurrentMedications)
    .find((medication) => medication?.hsId === packedMedication.medicationId);

  const medicationStatus = DrugUtils.getMedicationStatus(hsMedicationObject ?? packedMedication);
  const isPatch = PatchUtils.isPatch(drug);
  if (medicationStatus.isNew || medicationStatus.isChanged || packedMedication.route?.code === 'SID' || isPatch) {
    return false;
  }
  return true;
}

//basically to avoid cognitive complexity
async function selfAdministerMedication(
  medication: HSPackedMedication,
  drugList: HSDrug[],
  residentDetailsUtils: ResidentDetailsUtils,
  syncRounds: SyncRounds,
  syncTestResults: SyncTestResults,
  round: HSDoseRound,
  user: User,
  patient: HSPatient,
  facilityGroupId: string,
) {
  const drug = drugList.find((drug) => drug.hsId === medication.drugHsId);
  //create an administeredDrug object from the medication
  const administeredDrug = residentDetailsUtils.generateAdministeredDrug(
    medication,
    drug!,
    ReasonCode.SelfAdministered,
    [],
    user,
  );
  //enqueue the administered drug
  await DrugAdministrationUtils.administerDrug(
    '',
    administeredDrug,
    patient,
    parseInt(facilityGroupId),
    syncRounds,
    // FIX: missing a params here. Need to be passed from the parent
    syncTestResults,
    round,
    medication && 'doseTimestamp' in medication ? medication.doseTimestamp ?? '' : '',
  );
}

export interface ScheduledActivity {
  time: Date;
  administeredDrugId?: string;
  administeredDrugClinicalSystemId?: string;
  packedMedicationId?: number;
  kind: SyringeDriverActivityKind | PatchStatus;
  syringeActivity?: MedisphereSyringeDriverActivity;
  patchActivity?: HSPatchObservation;
}

// Generates a list of scheduled activity for a given syringe driver.
// This includes upcoming, passed, observed and unobserved observations, as well as the scheduled stop.
// eslint-disable-next-line sonarjs/cognitive-complexity
function generateScheduledActivityForSyringeDriver(
  activityStore: ReadonlyMap<string, MedisphereSyringeDriverActivity>,
  administeredDrug: HSAdministeredDrug,
) {
  const observationIntervalMinutes = 240;
  const syringeDriverActivity = itiriri(activityStore.values());

  const driverActivity = syringeDriverActivity
    .filter(
      (activity) =>
        (activity.administeredDrugId ?? false) === administeredDrug.hsId ||
        (activity.administeredDrugClinicalSystemId ?? false) === administeredDrug.clinicalSystemId,
    )
    .toArray();

  const starts = driverActivity
    .filter(
      (activity) =>
        !!(
          (activity.kind === SyringeDriverActivityKind.Start || activity.kind === SyringeDriverActivityKind.Restart) &&
          activity.createdAt
        ),
    )
    .sort((activity1, activity2) =>
      DateUtils.compareDateStringsDescending(activity1.createdAt, activity2.createdAt, true),
    );

  // If the driver hasn't been started, then it shouldn't be observable
  if (starts.length === 0) {
    return [];
  }

  // Find the most recent start, this should be the last start before finishing if the driver is already complete
  const mostRecentStart = starts[starts.length - 1];
  if (!mostRecentStart?.timeRemaining) {
    return [];
  }

  const end = driverActivity.find(
    (activity) => activity.kind === SyringeDriverActivityKind.Stop || activity.kind === SyringeDriverActivityKind.Cease,
  );
  const endTime = end?.createdAt
    ? DateUtils.toDate(end.createdAt)
    : DateUtils.getEndDateFromOpenApiDurationAndStartDate(
        mostRecentStart.timeRemaining,
        DateUtils.toDate(mostRecentStart.createdAt!),
      );

  const scheduledActivity: ScheduledActivity[] = [];

  // For each start time, generate observations until the next start would occur, or when the driver was/is expected to be stopped.
  for (let index = 0; index < starts.length; index++) {
    const startTime = DateUtils.toDate(starts[index].createdAt!);
    const observationBatchEndTime =
      index === starts.length - 1 ? endTime : DateUtils.toDate(starts[index + 1].createdAt!);
    let observationTime = datefns.addMinutes(startTime, observationIntervalMinutes);

    // Add observations while the time is before the end of this batch
    while (observationTime.valueOf() < observationBatchEndTime.valueOf()) {
      const newObservation: ScheduledActivity = {
        time: observationTime,
        administeredDrugId: administeredDrug.hsId?.toString(),
        administeredDrugClinicalSystemId: administeredDrug.clinicalSystemId ?? undefined,
        kind: SyringeDriverActivityKind.Observation,
        syringeActivity: driverActivity.find(
          (obs) => obs.scheduledAt && DateUtils.toDate(obs.scheduledAt).valueOf() === observationTime.valueOf(),
        ),
      };
      scheduledActivity.push(newObservation);
      observationTime = datefns.addMinutes(observationTime, observationIntervalMinutes);
    }
  }

  // Add any remaining observations that have been completed, but weren't caught in the above
  // This can apply if an observation is done early, and then the driver is restarted prior to the scheduled time.
  for (const observation of driverActivity.filter(
    (activity) =>
      activity.kind === SyringeDriverActivityKind.Observation &&
      !!(activity.scheduledAt || activity.createdAt) &&
      !scheduledActivity.some((obs) => obs.syringeActivity?.clinicalSystemId === activity.clinicalSystemId),
  )) {
    const existingObservation = {
      time: DateUtils.toDate(observation.scheduledAt ?? observation.createdAt!),
      administeredDrugId: administeredDrug.hsId?.toString(),
      administeredDrugClinicalSystemId: administeredDrug.clinicalSystemId ?? undefined,
      kind: SyringeDriverActivityKind.Observation,
      syringeActivity: observation,
    };
    scheduledActivity.push(existingObservation);
  }

  const endActivity = {
    time: endTime,
    administeredDrugId: administeredDrug.hsId?.toString(),
    administeredDrugClinicalSystemId: administeredDrug.clinicalSystemId ?? undefined,
    kind: end?.kind ?? SyringeDriverActivityKind.Stop,
    syringeActivity: end,
  };

  scheduledActivity.push(endActivity);

  return scheduledActivity;
}

// Generates a list of scheduled activity for a given patch.
// This includes upcoming, passed, observed and unobserved observations, as well as the scheduled stop.
// eslint-disable-next-line sonarjs/cognitive-complexity, max-lines-per-function
function generateScheduledActivityForPatch(
  patchObservationStore: ReadonlyMap<string, HSPatchObservation>,
  facilityGroupConfigurationStore: ReadonlyMap<string, FacilityGroupConfigurationDto>,
  facilityStore: ReadonlyMap<string, HSFacility>,
  patientStore: ReadonlyMap<string, HSPatient>,
  packedDayStore: ReadonlyMap<string, HSPackedPatientDay>,
  drugStore: ReadonlyMap<string, HSDrug>,
  packedMedication: HSPackedMedication,
) {
  const patchObservations = itiriri(patchObservationStore.values());

  const patient = itiriri(patientStore.values()).find(
    (patient) => patient.patientProfiles?.some((profile) => profile.hsId === packedMedication.profileHsId) ?? false,
  );

  if (!patient?.facility || !packedMedication.drugHsId) {
    return [];
  }

  const facility = facilityStore.get(patient.facility.toString());

  const drug = drugStore.get(packedMedication.drugHsId?.toString());

  if (!facility?.facilityGroupId || !drug) {
    return [];
  }

  const facilityGroupConfig = facilityGroupConfigurationStore.get(facility.facilityGroupId.toString());

  if (!facilityGroupConfig?.patchSightHour) {
    return [];
  }

  function getSightTimesForDay(day: Date) {
    return facilityGroupConfig!.patchSightHour!.map((hour) =>
      DateUtils.getEndDateFromOpenApiDurationAndStartDate(hour, datefns.startOfDay(day)),
    );
  }

  const patchActivity = patchObservations
    .filter((activity) => PatchUtils.getPatchOperationData(activity)?.packedMedicationId === packedMedication.hsId)
    .toArray();

  const applications = patchActivity.filter((activity) => PatchUtils.getPatchOperationData(activity)?.patchStatus === PatchStatus.Applied);
  if (!applications || applications.length === 0) {
    return [];
  }

  let startTime = DateUtils.toDate(packedMedication.doseTimestamp!);
  const appliedAt = DateUtils.toDate(applications[applications.length - 1].createdAt!)
  if (appliedAt > startTime) {
    startTime = appliedAt;
  }

  const patchRemovalObservation = patchActivity
    .filter((obs) =>
      [PatchStatus.Removed, PatchStatus.FallenOff].includes(
        PatchUtils.getPatchOperationData(obs)?.patchStatus ?? PatchStatus.ToSight,
      ),
    )
    .sort((a, b) => DateUtils.compareDateStringsDescending(a.createdAt, b.createdAt))[0];

  const patchReapplication = patchActivity
    .filter((obs) =>
      [PatchStatus.Reapplied].includes(PatchUtils.getPatchOperationData(obs)?.patchStatus ?? PatchStatus.ToSight),
    )
    .sort((a, b) => DateUtils.compareDateStringsDescending(a.createdAt, b.createdAt))[0];

  const patchRemovalPackedMedication = PatchUtils.getPatchRemovalPackedMedicationForPackedMedication(
    itiriri(packedDayStore.values()).toArray(),
    drug,
    packedMedication,
    patient.hsId!,
  );

  if (!patchRemovalObservation?.createdAt && !patchRemovalPackedMedication?.doseTimestamp) {
    return [];
  }

  const endTime =
    patchRemovalObservation?.createdAt &&
    (!patchReapplication?.createdAt ||
      DateUtils.toDate(patchRemovalObservation.createdAt).valueOf() >=
        DateUtils.toDate(patchReapplication.createdAt).valueOf())
      ? DateUtils.toDate(patchRemovalObservation.createdAt)
      : DateUtils.toDate(patchRemovalPackedMedication!.doseTimestamp!);

  const scheduledActivity: ScheduledActivity[] = [];

  const sightTimes = range(0, datefns.differenceInDays(endTime, startTime) + 2)
    .flatMap((num) => getSightTimesForDay(datefns.addDays(startTime, num)))
    .sort((a, b) => DateUtils.compareDatesDescending(a, b, true));

  let index = sightTimes.findIndex((time) => startTime.valueOf() < time.valueOf());

  if (index === -1) {
    return [];
  }

  // Add observations while the time is before the end of this batch
  while (index < sightTimes.length && sightTimes[index].valueOf() < endTime.valueOf()) {
    const newObservation = {
      time: sightTimes[index],
      packedMedicationId: packedMedication.hsId,
      kind: PatchStatus.Sighted,
      patchActivity: patchActivity
        .filter((obs) => PatchUtils.getPatchOperationData(obs)?.patchStatus === PatchStatus.Sighted)
        .find((obs) => {
          if (!obs.createdAt) {
            return false;
          }
          const opData = PatchUtils.getPatchOperationData(obs);
          if (!opData) {
            return false;
          }
          const sightableStartingFrom = DateUtils.toDate(
            getRoundWindow(sightTimes[index], facility.facilityGroupId!, facilityGroupConfigurationStore).start,
          );
          const sightableUntil =
            index + 1 === sightTimes.length
              ? undefined
              : DateUtils.toDate(
                  getRoundWindow(sightTimes[index + 1], facility.facilityGroupId!, facilityGroupConfigurationStore)
                    .start,
                );
          return (
            sightTimes[index].valueOf() === DateUtils.toDate(opData.patchTime).valueOf() ||
            (DateUtils.toDate(opData.patchTime).valueOf() > sightableStartingFrom.valueOf() &&
              (!sightableUntil || DateUtils.toDate(opData.patchTime).valueOf() <= sightableUntil.valueOf()))
          );
        }),
    };
    scheduledActivity.push(newObservation);
    index++;
  }

  const endActivity = {
    time:
      !patchRemovalObservation ||
      PatchUtils.getPatchOperationData(patchRemovalObservation)?.patchStatus === PatchStatus.FallenOff
        ? DateUtils.toDate(patchRemovalPackedMedication!.doseTimestamp!)
        : endTime,
    packedMedicationId: packedMedication.hsId,
    kind: PatchStatus.Removed,
    patchActivity:
      patchRemovalObservation &&
      PatchUtils.getPatchOperationData(patchRemovalObservation)?.patchStatus === PatchStatus.Removed
        ? patchRemovalObservation
        : undefined,
  };

  scheduledActivity.push(endActivity);

  return scheduledActivity;
}

function generateSyringeDriverActivityFromPackedMedication(
  packedMed: HSPackedMedication,
  residentDetailsUtils: ResidentDetailsUtils,
  syringeDriverActivityStore: ReadonlyMap<string, MedisphereSyringeDriverActivity>,
) {
  const administeredDrug = residentDetailsUtils.getLatestAdministrationForScheduledDrug(packedMed);
  if (!administeredDrug) {
    return undefined;
  }
  return generateScheduledActivityForSyringeDriver(syringeDriverActivityStore, administeredDrug);
}
